<template>
  <v-list dense>
    <template v-if="currentUser">
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>
            {{ currentUser.username }}
          </v-list-item-title>
          <v-list-item-subtitle>Logged In</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
    </template>

    <v-list-item v-for="item in items" :key="item.title" link :to="item.route">
      <v-list-item-icon class="yellow--color">
        <v-icon class="yellow--color">{{ item.icon }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <template v-if="currentUser">
      <v-divider></v-divider>
      <v-list-item link @click="logoutAction()">
        <v-list-item-icon>
          <v-icon>{{ "mdi-logout" }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-else>
      <v-divider></v-divider>
      <v-list-item link :to="'/login'">
        <v-list-item-icon>
          <v-icon>{{ "mdi-login" }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Login</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <AuthLogoutDialog v-if="currentUser" @logout="logoutAction()" />
  </v-list>
</template>

<style scoped>
</style>

<script>
import AuthLogoutDialog from "@/components/AuthLogoutDialog.vue";

export default {
  name: "NavBarMenu",
  components: {
    AuthLogoutDialog,
  },

  // async mounted() {
  //   console.log("NavDrawerMenu Mounted");
  //   console.log(this.items);
  // },
  props: ["currentUser", "items"],
  watch: {
    drawer: function () {
      console.log(this.drawer);
    },
  },

  methods: {
    async logoutAction() {
      console.log("logoutAction");
      this.$emit("logout");
    },
  },
  data: () => ({
    drawer: true,
  }),
};
</script>
