import Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import ButtonView from "@ckeditor/ckeditor5-ui/src/button/buttonview";
import AttributeCommand from "@ckeditor/ckeditor5-basic-styles/src/attributecommand";

//import defaultIcon from "./theme/icons/default.svg";

export default class GgiAttributesPlugin extends Plugin {
  static get requires() {
    return [ggiAttributesMain];
  }

  static get pluginName() {
    return "GgiAttributesPlugin";
  }
}

export class ggiAttributesMain extends Plugin {
  init() {
    const editor = this.editor;
    //console.log({ editor });
    const items = editor.config.get("GgiAttributes.items");
    //console.log({ items });

    for (let i = 0; i < items.length; i++) {
      const modelName = this._safeGet(items[i].modelName, "");
      const viewName = this._safeGet(items[i].viewName, "");
      const attrKey = this._safeGet(items[i].attrKey, "");
      const attrValue = this._safeGet(items[i].attrValue, "");
      const label = this._safeGet(items[i].label, "");
      //      const icon = this._safeGet(items[i].icon, defaultIcon);

      if (modelName === "" || viewName === "" || attrKey === "") continue; //disabled

      const comid = `${modelName}-${attrKey}-${attrValue}`;

      //schema
      editor.model.schema.extend(modelName, { allowAttributes: comid });

      //model-view conversion
      // Update class="{attrValue}" OR {attrName}-{attrName}="{attrValue}"
      //   for example, data-is-featured="true"
      editor.conversion.attributeToAttribute({
        model: {
          name: modelName,
          key: comid,
        },
        view: {
          name: viewName,
          key: attrKey,
          value: attrValue,
        },
      });

      //register command
      //console.log("Before Register Command");
      editor.commands.add(comid, new AttributeCommand(editor, comid));
      //console.log("After Register Command");

      // Add code button to feature components.
      editor.ui.componentFactory.add(comid, (locale) => {
        //console.log("component add");
        const command = editor.commands.get(comid);
        //console.log({ command });
        const view = new ButtonView(locale);

        view.set({
          label: label,
          //icon: icon,
          tooltip: true,
        });

        view.bind("isOn", "isEnabled").to(command, "value", "isEnabled");

        // Execute command.
        this.listenTo(view, "execute", () => editor.execute(comid));
        //console.log({ view });
        return view;
      });
    } //...next i
  }

  _safeGet(input, safeDefault) {
    if (
      typeof input !== "undefined" &&
      (input || input === false || input === 0)
    ) {
      return input;
    } else {
      return safeDefault;
    }
  }
}
