<template>
  <v-dialog v-model="dialog" persistent max-width="290">
    <template v-slot:activator="{ on, attrs }">
      <v-btn small rounded text v-bind="attrs" v-on="on">Logout</v-btn>
    </template>
    <v-card>
      <v-card-title class="headline"> Logout? </v-card-title>
      <v-card-text>Username...</v-card-text>
      <v-card-actions>
        <v-btn color="red darken-1" text @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="logoutClick()"> OK </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


    <v-btn
      v-if="currentUser"
      @click="showModal = true"
      color="yellow"
      small
      rounded
      text
      >Logout</v-btn
    >


<script>
// <v-list-item-icon>
//   <v-icon>{{ "mdi-logout" }}</v-icon>
// </v-list-item-icon>
export default {
  name: "AuthLogoutDialog",
  data: () => {
    return {
      dialog: false,
    };
  },
  methods: {
    logoutClick() {
      this.dialog = false;
      this.$emit("logout");
    },
  },
};
</script>
