import {
  S3Client,
  ListObjectsV2Command,
  GetObjectCommand,
  PutObjectCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
} from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { authGetSession } from "@/api/ggiAuth";
import { getMimeType } from "./fmUtil";
import APP from "../appConfig";
const path = require("path");
import File from "./File";

export default class awsClientS3 {
  constructor() {
    this.cognitoIdentityClient = new CognitoIdentityClient({
      region: APP.AWS.REGION,
    });
    this.provider =
      "cognito-idp." +
      APP.AWS.REGION +
      ".amazonaws.com/" +
      APP.COGNITO.USER_POOL_ID;
    this.logins = {};
    this.s3 = null;
  }

  async init() {
    console.log("awsClientS3 init");
    let session = await authGetSession();
    this.logins[this.provider] = session.getIdToken().getJwtToken();
    let cred = fromCognitoIdentityPool({
      client: this.cognitoIdentityClient,
      identityPoolId: APP.COGNITO.IDENTITY_POOL_ID,
      logins: this.logins,
    });
    try {
      this.s3 = new S3Client({
        region: APP.AWS.REGION,
        credentials: cred,
      });
      this.s3.middlewareStack.add(
        (next, context) => (args) => {
          console.log("S3 MIDDLEWARE");
          console.log({ context });
          console.log({ args });
          // args.request.headers["Custom-Header"] = "value";
          return next(args);
        },
        {
          step: "build",
        }
      );
    } catch (err) {
      console.error(`init Error ${err}`);
      throw err;
    }
  }

  async get(bucket, key) {
    console.log(`awsClientS3 get Bucket: ${bucket} Key: ${key}`);
    let params = this.validateS3Params(bucket);
    key = key.trim();
    if (!key) {
      throw new Error("Key is a required parameter");
    }
    params.Key = key;

    try {
      let getRes = await this.s3.send(new GetObjectCommand(params));
      console.log({ getRes });
      let body = await this.fetchStream(getRes.Body);
      console.log({ body });
      return body;
    } catch (err) {
      console.error(`get Error: ${err}`);
      return err;
      // throw err;
    }
  }

  async put(bucket, key, body) {
    console.log(`awsClientS3 put Bucket: ${bucket} Key: ${key}`);
    let params = this.validateS3Params(bucket);
    key = key.trim();
    if (!key) {
      throw new Error("Key is a required parameter");
    }
    let contentType = getMimeType(path.extname(key).replace(".", ""));
    params.Key = key;
    params.ContentType = contentType;
    params.Body = body;
    console.log({ params });

    try {
      let putRes = await this.s3.send(new PutObjectCommand(params));
      console.log(
        "Successfully uploaded object: " + params.Bucket + "/" + params.Key
      );
      return putRes;
    } catch (err) {
      console.error(`put Error: ${err}`);
      throw err;
    }
  }

  async delFile(bucket, key) {
    console.log(`awsClientS3 delFile: Bucket ${bucket}; Key ${key}`);
    let params = { Bucket: bucket, Key: key };

    try {
      //      const response = await client.send(command);

      const command = new DeleteObjectCommand(params);
      console.log({ command });

      const res = await this.s3.send(command);
      console.log({ res });

      return res;
    } catch (err) {
      console.log(`Error deleting "${key}": ${err.message}`);
      throw err.message;
    }
  }

  async delFiles(bucket, keys) {
    console.log(`awsClientS3 delFiles: Bucket ${bucket}; Keys ${keys}`);
    const params = { Bucket: bucket, Delete: { Objects: keys }, Quiet: true };
    try {
      //const s3 = await init();
      const res = await this.s3.send(new DeleteObjectsCommand(params));
      //      console.log({ res });
      return res;
    } catch (err) {
      console.log(`Error deleting "${keys}": ${err.message}`);
      throw err.message;
    }
  }

  async driveGet(drive, key) {
    console.log(`awsClientS3 driveGet: ${drive}/${key}`);
    let bucket = APP.FM.DRIVES[drive].bucket;
    //    let prefix = APP.FM.DRIVES[drive].prefix;
    key = key.trim();
    let driveGetRes = await this.get(bucket, key);
    return driveGetRes;
  }

  async drivePut(drive, key, body) {
    console.log(`awsClientS3 driveGet: ${drive}/${key}`);
    let bucket = APP.FM.DRIVES[drive].bucket;
    //    let prefix = APP.FM.DRIVES[drive].prefix;
    key = key.trim();
    let drivePutRes = await this.put(bucket, key, body);
    return drivePutRes;
  }

  async driveListContents(drive, dir = null) {
    console.log(`awsClientS3 driveListContents: Drive: ${drive} Dir: ${dir}`);
    let bucket = APP.FM.DRIVES[drive].bucket;
    let prefix = APP.FM.DRIVES[drive].prefix;
    let prefixDir = null;
    prefixDir = [prefix, dir].filter(Boolean).join("/");
    console.log({ prefixDir });

    let driveListContentsRes = await this.listContents(bucket, prefixDir);
    return driveListContentsRes;
  }

  async listContents(bucket, prefix = null, delimiter = null) {
    let params = this.validateS3Params(bucket, prefix, delimiter);
    console.log({ params });

    try {
      let keys = await this.s3.send(new ListObjectsV2Command(params));
      console.log({ keys });
      // filter out directory folder if present
      let contents = keys.Contents.filter((key) => {
        return key.Key !== prefix;
      });
      console.log({ contents });
      return contents;
    } catch (err) {
      console.error(`awsClientS3 list Error ${err}`);
      return err;
    }
  }
  createFiles(bucket, prefix, listContents) {
    console.log(`awsClientS3: createFiles ${bucket} ${listContents}`);
    let files = [];
    for (let i = 0; i < listContents.length; i++) {
      files.push(new File(bucket, prefix, listContents[i]));
    }
    //console.log({ files });
    return files;
  }

  // PRIVATE FUNCTIONS

  async fetchStream(stream) {
    //console.log("fetchStream");
    const reader = stream.getReader();
    const utf8Decoder = new TextDecoder("utf-8");

    let data = "";
    let result = await reader.read();
    while (!result.done) {
      data = data += utf8Decoder.decode(result.value);
      result = await reader.read();
    }
    //console.log({ data });
    return data;
  }

  validateS3Params(bucket, prefix = null, delimiter = null) {
    // Validate Params
    if (!bucket) throw "Bucket is a required parameter";
    // TODO: Get rid pf special case prefix==="/"
    if (prefix === "/" || prefix === "") prefix = null;
    if (prefix !== null) {
      if (!prefix.endsWith("/")) throw "Prefix must end with a slash '/'";
    }

    // Create Command Params
    const params = {};
    params.Bucket = bucket;
    if (prefix !== null) params.Prefix = prefix;
    if (delimiter !== null) params.Delimiter = delimiter;
    return params;
  }
}
