import {
  CognitoUserPool,
  CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import APP from "../appConfig";

export {
  authRegister,
  authConfirm,
  authGetSession,
  authLogin,
  authLogout,
  authGetCurrentUser,
  authGetIdToken,
};

const userPool = new CognitoUserPool({
  UserPoolId: APP.COGNITO.USER_POOL_ID,
  ClientId: APP.COGNITO.APP_CLIENT_ID,
});

const setCognitoUserAttribute = (attributeKey, attributeValue) => {
  let data = {
    Name: attributeKey,
    Value: attributeValue,
  };

  return new CognitoUserAttribute(data);
};

const authRegister = (values) => {
  console.log({ values });
  let { email, password } = values;
  let attributeList = [];

  attributeList.push(setCognitoUserAttribute("name", email));

  return new Promise((resolve, reject) =>
    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    })
  );
};

const authConfirm = (username, confirmationCode) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };

  console.log({ userData });
  console.log({ confirmationCode });

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.confirmRegistration(confirmationCode, true, function(
      err,
      result
    ) {
      if (err) {
        console.log(err);
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

const authLogin = (username, password) => {
  const user = new CognitoUser({ Username: username, Pool: userPool });
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  return new Promise((resolve, reject) =>
    user.authenticateUser(authenticationDetails, {
      onSuccess: (loginSession) => {
        console.log(
          "access token + " + loginSession.getAccessToken().getJwtToken()
        );
        console.log({ loginSession });
        resolve(loginSession);
      },
      onFailure: (err) => reject(err),
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log("New Password Is Required");
        console.log({ userAttributes });
        console.log({ requiredAttributes });
        alert("New Password Is Required");
      },
    })
  );
};

const authGetSession = () => {
  console.log("authGetSession");
  return new Promise((resolve, reject) => {
    const currentUser = userPool.getCurrentUser();

    if (currentUser != null) {
      currentUser.getSession((err, session) => {
        // Error getting Session
        if (err) {
          console.log({ err });
          //ERR_CONNECTION_RESET
          reject(err);
        }

        if (session.isValid()) resolve(session);
        // Session is not valid
        // TODO: Timeout?
        else reject(err);
      });
      // No User
    } else resolve(null);
  });
};

const authLogout = () => {
  console.log("authLogout");
  const currentUser = userPool.getCurrentUser();

  if (currentUser !== null) {
    currentUser.signOut();
  }
};

const authGetCurrentUser = () => {
  console.log("authGetCurrentUser");
  return userPool.getCurrentUser();
};

const authGetIdToken = () => {
  console.log("authGetIdToken");
  return new Promise((resolve, reject) => {
    if (authGetCurrentUser() == null) {
      resolve(null);
    }
    authGetCurrentUser().getSession((err, session) => {
      if (err) return reject(err);
      if (session.isValid()) {
        resolve(session.getIdToken().getJwtToken());
      }
      reject(Error("Session is invalid"));
    });
  });
};
