<template>
  <article
    class="
      mx-auto
      my-2
      prose prose-sm
      sm:prose
      lg:prose-lg
      xl:prose-2xl
      overflow-auto
    "
  >
    <ckeditor
      v-if="render"
      :editor="editor"
      v-model="editorData"
      :config="editorConfig"
      @input="onEditor"
      @ready="initEditor"
    ></ckeditor>
  </article>
</template>

<script>
//import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor.js";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor.js";

import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment.js";
import AutoImage from "@ckeditor/ckeditor5-image/src/autoimage.js";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat.js";
import Autolink from "@ckeditor/ckeditor5-link/src/autolink.js";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote.js";
import BlockToolbar from "@ckeditor/ckeditor5-ui/src/toolbar/block/blocktoolbar";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold.js";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code.js";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials.js";
import Heading from "@ckeditor/ckeditor5-heading/src/heading.js";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline.js";
import Image from "@ckeditor/ckeditor5-image/src/image.js";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption.js";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert.js";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize.js";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle.js";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar.js";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload.js";
import Indent from "@ckeditor/ckeditor5-indent/src/indent.js";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock.js";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic.js";
import Link from "@ckeditor/ckeditor5-link/src/link.js";
import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage.js";
import List from "@ckeditor/ckeditor5-list/src/list.js";
import ListStyle from "@ckeditor/ckeditor5-list/src/liststyle.js";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed.js";
import MediaEmbedToolbar from "@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar.js";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph.js";
import ParagraphButtonUI from "@ckeditor/ckeditor5-paragraph/src/paragraphbuttonui";
import PasteFromOffice from "@ckeditor/ckeditor5-paste-from-office/src/pastefromoffice";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough.js";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript.js";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript.js";
import Table from "@ckeditor/ckeditor5-table/src/table.js";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar.js";
import TextTransformation from "@ckeditor/ckeditor5-typing/src/texttransformation.js";
import Title from "@ckeditor/ckeditor5-heading/src/title.js";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline.js";

import CKEditor from "@ckeditor/ckeditor5-vue2";
//import CKEditorInspector from "@ckeditor/ckeditor5-inspector";

import { CMS_CkeditorImagePlugin } from "@/api/cms/CMS_CkeditorImagePlugin";
//import TableClassesPlugin from "@/api/TableClassesPlugin";
//import ImageClassesPlugin from "@/api/ImageClassesPlugin";
import GgiAttributesPlugin from "@/api/ggiAttributesPlugin";

export default {
  name: "CMSEditorCk",
  props: ["contentBody", "isDirty", "imageIndex"],
  components: {
    ckeditor: CKEditor.component,
  },
  created() {
    console.log(`CMSEditorCk: Created`);
    this.editorConfig.extraPlugins = [
      // TableClassesPlugin,
      // ImageClassesPlugin,
      GgiAttributesPlugin,
      CMS_CkeditorImagePlugin,
    ];
  },
  async mounted() {
    console.log(`CMSEditorCk: Mounted`);
    this.render = true;
  },
  watch: {
    contentBody() {
      // console.log(
      //   `CMSEditorCk Watch contentBody: contentBody ${this.contentBody}`
      // );
      this.editorData = this.contentBody;
    },
  },
  methods: {
    onEditor(editorData) {
      console.log(`CMSEditorCk onEditor`);
      //      console.log({ editorData });
      this.$emit("onEditor", editorData, this.titlePlugin.getTitle());
    },
    initEditor(editor) {
      console.log("CMSEditorCk: initEditor");
      editor.imageIndex = this.imageIndex;
      this.titlePlugin = editor.plugins.get("Title");
      // this.myEditor = editor;
      // CKEditorInspector.attach(editor);
    },
  },
  data() {
    return {
      render: false,
      editor: BalloonEditor,
      // myEditor: null,
      titlePlugin: null,
      editorData: this.contentBody,
      editorConfig: {
        plugins: [
          Alignment,
          AutoImage,
          Autoformat,
          Autolink,
          BlockQuote,
          BlockToolbar,
          Bold,
          Code,
          Essentials,
          Heading,
          HeadingButtonsUI,
          HorizontalLine,
          Image,
          ImageCaption,
          ImageInsert,
          ImageResize,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListStyle,
          MediaEmbed,
          MediaEmbedToolbar,
          Paragraph,
          ParagraphButtonUI,
          PasteFromOffice,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCellProperties,
          TableProperties,
          TableToolbar,
          TextTransformation,
          Title,
          TodoList,
          Underline,
        ],
        extraPlugins: null,
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "strikethrough",
            "subscript",
            "superscript",
            "link",
            "|",
            "bulletedList",
            "numberedList",
            "insertTable",
            "todoList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "code",
            "|",
            "undo",
            "redo",
          ],
        },
        blockToolbar: [
          "heading",
          "|",
          "alignment",
          "|",
          "bulletedList",
          "numberedList",
          "insertTable",
          "todoList",
          "|",
          "uploadImage",
          "link",
          "mediaEmbed",
          "|",
          "outdent",
          "indent",
          "|",
          "blockQuote",
          "code",
          "|",
          "undo",
          "redo",
        ],
        table: {
          /*... other options here ...*/
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
          //${modelName}-${attrKey}-${attrValue}
          tableToolbar: ["table-class-subtitle"],
        },
        GgiAttributes: {
          // Comand: modelName-attrType-attrName (imageBlock-data-is-featured)
          // Attrubute: attrType-attrName="attrValue" (data-is-featured="true")
          // Button Name: attrName (is-featured)
          items: [
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "data-is-featured",
              attrValue: "true",
              icon: "",
              label: "Featured",
            },
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "class",
              attrValue: "style-25",
              icon: "",
              label: "25%",
            },
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "class",
              attrValue: "style-50",
              icon: "",
              label: "50%",
            },
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "class",
              attrValue: "style-75",
              icon: "",
              label: "75%",
            },
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "class",
              attrValue: "media-style-align-left",
              icon: "",
              label: "Left",
            },
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "class",
              attrValue: "media-style-align-center",
              icon: "",
              label: "Center",
            },
            {
              modelName: "media",
              viewName: "figure",
              attrKey: "class",
              attrValue: "media-style-align-right",
              icon: "",
              label: "Right",
            },
            {
              modelName: "imageBlock",
              viewName: "figure",
              attrKey: "data-is-featured",
              attrValue: "true",
              icon: "",
              label: "Featured",
            },
            {
              modelName: "table",
              viewName: "figure",
              attrKey: "class",
              attrValue: "subtitle",
              icon: "",
              label: "Subtitle",
            },
          ],
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: {
                name: "h2",
                classes: "test-heading1",
              },
              title: "Heading 1",
              class: "test-heading1",
            },
            {
              model: "heading2",
              view: "h3",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h4",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h5",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "Subtitle",
              view: {
                name: "h2",
                classes: "subtitle",
              },
              title: "Subtitle",
              class: "ck-heading_heading2_subtitle",

              // It needs to be converted before the standard 'heading2'.
              converterPriority: "high",
            },
          ],
        },
        image: {
          // Configure the available styles.
          styles: {
            options: [
              "alignLeft",
              "alignCenter",
              "alignRight",
              "block",
              "side",
              {
                name: "featured",
                //                icon: sideIcon,
                title: "Featured image",
                className: "image-featured",
                modelElements: ["imageBlock"],
              },
            ],
          },

          // Configure the available image resize options.
          resizeOptions: [
            {
              name: "imageResize:original",
              label: "Original",
              value: null,
            },
            {
              name: "imageResize:25",
              label: "25%",
              value: "25",
            },
            {
              name: "imageResize:50",
              label: "50%",
              value: "50",
            },
            {
              name: "imageResize:75",
              label: "75%",
              value: "75",
            },
          ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          //${modelName}-${attrKey}-${attrValue}
          toolbar: [
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageResize",
            "|",
            "imageTextAlternative",
            "|",
            "imageStyle:featured",
            "imageBlock-data-is-featured-true",
            // "imageclass-style2",
          ],
        },
        mediaEmbed: {
          // Configure the available styles.
          previewsInData: true,
          styles: {
            options: [
              "alignLeft",
              "alignCenter",
              "alignRight",
              "block",
              "side",
              {
                name: "featured",
                //                icon: sideIcon,
                title: "Featured image",
                className: "image-featured",
                modelElements: ["imageBlock"],
              },
            ],
          },

          // Configure the available image resize options.
          resizeOptions: [
            {
              name: "imageResize:original",
              label: "Original",
              value: null,
            },
            {
              name: "imageResize:50",
              label: "50%",
              value: "50",
            },
            {
              name: "imageResize:75",
              label: "75%",
              value: "75",
            },
          ],

          // You need to configure the image toolbar, too, so it shows the new style
          // buttons as well as the resize buttons.
          //${modelName}-${attrKey}-${attrValue}
          toolbar: [
            "bold",
            "italic",
            "|",
            "undo",
            "redo",
            "|",
            "imageStyle:alignLeft",
            "imageStyle:alignCenter",
            "imageStyle:alignRight",
            "|",
            "imageResize",
            "|",
            //"mediaStyle:featured",
            "media-data-is-featured-true",
            "media-class-style-25",
            "media-class-style-50",
            "media-class-style-75",
            "media-class-media-style-align-left",
            "media-class-media-style-align-center",
            "media-class-media-style-align-right",
            // "imageclass-style2",
          ],
        },
      },
    };
  },
};
</script> 

<style >
@import "../css/main.css";
h1 {
  text-align: center;
  margin: 20px;
}
.style-25 {
  width: 25%;
}
.style-50 {
  width: 50%;
}
.style-75 {
  width: 75%;
}

/* .test-heading1 {
  margin: 20px;
} */

/* Styles for the heading in the content and for the dropdown item. */
/* Adds class="subtitle"; configured in Heading Options */
h2.subtitle,
.ck-content .ck.ck-button.ck-heading_heading2_subtitle {
  color: #6e6d6e;
}
</style>