// Medium
// <img class="graf-image" data-image-id="1*oHT0fCABa2-3P7J1_cD0AA.png" data-width="1164" data-height="777" data-is-featured="true" src="https://cdn-images-1.medium.com/max/800/1*oHT0fCABa2-3P7J1_cD0AA.png">

// The imageIndex functionality (uploadImage) is passed through the editor
// for the adapter plugin to use

class CMS_CkeditorImageAdapter {
  constructor(loader, editor) {
    console.log("CMS_CkeditorImageAdapter Constructor");
    // The file loader instance to use during the upload.
    this.loader = loader;
    this.editor = editor;
  }

  upload() {
    console.log("CMS_CkeditorImageAdapter upload");
    return this.loader.file.then(async (file) => {
      try {
        let imageItem = await this.editor.imageIndex.uploadImage(file);
        console.log({ imageItem });
        return imageItem.imgUrls;
      } catch (err) {
        console.log("CMS_CkeditorImageAdapter Error");
        console.error(err);
        throw err;
      }
    });
  }

  // Aborts the upload process.
  abort() {
    console.log("abort");
  }
}

export async function CMS_CkeditorImagePlugin(editor) {
  console.log("CMS_CkeditorImagePlugin");
  // console.log({ editor });
  if (editor.plugins.has("FileRepository")) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new CMS_CkeditorImageAdapter(loader, editor);
    };
  } else console.error("CMS_CkeditorImagePlugin NOT LOADED");
}
