<template>
  <div :style="bgImg" class="bgImg"></div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    bgImg: {
      backgroundImage: "",
    },
  }),
};
</script>

<style>
.bgImg {
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>  