import CFG from "@/api/cms/CMS_Config";
import CMS_Index from "@/api/cms/CMS_Index";
import CMS_Item_Article from "@/api/cms/CMS_Item_Article";

export default class CMS_Index_Article extends CMS_Index {
  constructor(s3Client) {
    super(
      s3Client,
      CFG.cms.contentTypes.article.indexBucket,
      CFG.cms.contentTypes.article.indexKey
    );
  }

  async create(name, cmsItem = {}, body = null) {
    console.log(`CMS_Index_Article create`);
    try {
      let articleItem = new CMS_Item_Article(name, cmsItem);
      articleItem.s3PutResult = await this.s3Client.put(
        this.bucket,
        articleItem.contentKey,
        body
      );

      await super.update(articleItem);

      console.log({ articleItem });
      return articleItem;
    } catch (err) {
      console.log(`CMS_Index_Article create: ${err}`);
      console.log({ name });
      throw err;
    }
  }

  async readContent(id) {
    console.log(`CMS_Index_Article readContent: ${id}`);
    if (id !== null) {
      try {
        let content = await this.s3Client.get(
          this.bucket,
          this.index[id].contentKey
        );
        return content;
      } catch (err) {
        console.log(`CMS_Index_Article readContent: Content not found: ${err}`);
        console.log({ id });
        throw err;
      }
    } else {
      return null;
    }
  }

  async updateContent(id, content) {
    console.log(`CMS_Index_Article updateContent ${id}`);
    if (id !== null) {
      try {
        this.index[id].updateContentResult = await this.s3Client.put(
          this.bucket,
          this.index[id].contentKey,
          content
        );
        await super.update(this.index[id]);
        return this.index[id];
      } catch (err) {
        console.log(`CMS_Index_Article updateContent: Item not found: ${err}`);
        console.log({ id });
        throw err;
      }
    } else {
      return null;
    }
  }
  isIdUpdated(id, name) {
    let indexMeta = {};
    let checkItem = new CMS_Item_Article(name, indexMeta);
    return checkItem.id !== id;
  }
}
