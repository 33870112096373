// just keep track of activeType, Id and route to contentMgr
import CFG from "@/api/cms/CMS_Config";
import CMS_Index_Article from "@/api/cms/CMS_Index_Article";
import CMS_Index_Image from "@/api/cms/CMS_Index_Image";
import awsClientS3 from "@/api/awsClientS3";
import awsClientLambda from "@/api/awsClientLambda";
import awsClientCloudFront from "@/api/awsClientCloudFront";

export default class CMS {
  constructor() {
    console.log("CMS constructor");
    this.directory = {};
    this.cmsTree = [];
    this.cmsIndexes = {};
    this.s3Client = new awsClientS3();
    this.lambdaClient = new awsClientLambda();
    this.cloudFrontClient = new awsClientCloudFront();
  }

  async init() {
    console.log("CMS init");
    await this.s3Client.init();
    await this.lambdaClient.init();
    await this.cloudFrontClient.init();
    // load the cmsIndexes
    // for each content type - get the index
    // index[contentType]=getIndex
    for (const contentType in CFG.cms.contentTypes) {
      this.cmsIndexes[contentType] = this.initCmsIndex(contentType);
      await this.cmsIndexes[contentType].init();
    }
    // console.log("CMS Init Complete");
    // console.log(this.cmsIndexes);
  }

  getIndex(contentType) {
    return this.cmsIndexes[contentType];
  }
  getIndexFolderName(contentType) {
    return CFG.cms.contentTypes[contentType].folderName;
  }

  createCmsTree() {
    console.log("CMS createCmsTree");
    const cmsTree = [];
    let i = 0;
    for (const contentType in CFG.cms.contentTypes) {
      cmsTree.push({
        id: contentType,
        type: contentType,
        name: CFG.cms.contentTypes[contentType].folderName,
        isDir: true,
        children: [],
      });

      const list = this.cmsIndexes[contentType].list();
      for (const item in list) {
        cmsTree[i].children.push(list[item]);
      }
      i++;
    }
    // console.log({ cmsTree });
    this.cmsTree = cmsTree;
    return this.cmsTree;
  }

  async readContent(contentType, id) {
    console.log("CMS readContent");
    return await this.cmsIndexes[contentType].readContent(id);
  }

  read(contentType, id) {
    console.log("CMS read");
    if (contentType === null || id === null) return null;
    // Content Folder
    else if (contentType === id)
      return { id: id, type: contentType, name: id, isDir: true, children: [] };
    else {
      return this.cmsIndexes[contentType].read(id);
    }
  }

  list(contentType) {
    return contentType === null ? [] : this.cmsIndexes[contentType].list();
  }

  async create(contentType, name, cmsItem = {}, body = null) {
    console.log(`CMS create`);
    try {
      let item = await this.cmsIndexes[contentType].create(name, cmsItem, body);
      return item;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async update(contentType, id, cmsItem, body, name) {
    console.log(`CMS update`);
    // console.log(contentType);
    // console.log(id);
    // console.log(body);
    // console.log(name);
    // console.log(featuredImgSrc);
    try {
      // If id updated, then RENAME
      // else if index meta (i.e. featured image) updated, then update index
      // Then, update the article
      let item = null;
      if (this.cmsIndexes[contentType].isIdUpdated(id, name)) {
        console.log("RENAME");
        // TODO: create rename that does not lose original Article attributes
        await this.delete({ id: id, type: contentType });
        item = await this.create(contentType, name, cmsItem, body);
      } else {
        // console.log("CMS update - updateContent");
        item = await this.cmsIndexes[contentType].updateContent(id, body);
      }

      return item;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async delete(item) {
    console.log(`CMS delete`);
    try {
      let res = null;
      if (item.id !== null) {
        res = await this.cmsIndexes[item.type].delete(item);
      }
      return res;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async publish() {
    console.log("CMS publish");
    try {
      let lambdaClientRes = await this.lambdaClient.publish(CFG.site.domain);
      // console.log({ lambdaClientRes });
      return lambdaClientRes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async invalidateCache(prefix = null, id = null) {
    console.log("CMS invavlidateCache");
    const items = [];

    // No params = clear all
    if (prefix === null && id === null) items.push("/*");
    // if only folder provided, clear the the folder
    else if (prefix !== null && id === null) items.push(`/${prefix}/*`);
    // otherwise clear the index for folder and the specific file
    else {
      items.push(`/${prefix}/`);
      items.push(`/${prefix}/${id}/`);
    }

    var params = {
      DistributionId: CFG.site.distributionId,
      InvalidationBatch: {
        CallerReference: `invalidation-${Date.now()}`,
        Paths: {
          Quantity: items.length,
          Items: items,
        },
      },
    };

    try {
      let cloudFrontClientRes = await this.cloudFrontClient.invalidateCache(
        params
      );
      console.log({ cloudFrontClientRes });
      return cloudFrontClientRes;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  //Private Methods

  initCmsIndex(contentType) {
    // console.log("CMS initCmsIndex");
    let cmsIndex = null;
    switch (contentType) {
      case "image":
        // console.log("Initializing CMS_Index_Image");
        cmsIndex = new CMS_Index_Image(this.s3Client, this.lambdaClient);
        break;
      case "article":
        // console.log("Initializing CMS_Index_Article");
        cmsIndex = new CMS_Index_Article(this.s3Client);
        break;
      default:
        console.log("INVALID cmsIndex TYPE");
    }
    return cmsIndex;
  }
}
