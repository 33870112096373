<template>
  <v-row no-gutters>
    <v-toolbar-title>Site Administration</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn :to="'/register'" color="yellow" small rounded text>Register</v-btn>

    <AuthLogoutDialog v-if="currentUser" @logout="logoutAction()" />

    <v-btn v-else :to="'/login'" color="yellow" small rounded text>Login</v-btn>
  </v-row>
</template>

<style scoped>
</style>

<script>
import AuthLogoutDialog from "@/components/AuthLogoutDialog.vue";

export default {
  name: "NavHeaderBar",
  components: {
    AuthLogoutDialog,
  },
  // async mounted() {
  //   console.log("Mounting");
  // },
  props: ["currentUser"],
  methods: {
    logoutAction() {
      console.log("logoutAction");
      this.$emit("logout");
    },
  },
  data: () => ({
    showModal: false,
  }),
};
</script>
