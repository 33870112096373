<template >
  <div v-if="render">
    <CMSBrowserToolbar
      v-if="action === 'browse'"
      :cmsId="cmsId"
      :cmsItem="cmsItem"
      :cmsItems="cmsItems"
      :isPublishing="isPublishing"
      @onNew="onNew"
      @cmsEditRoute="cmsEditRoute"
      @onDelete="onDelete"
      @onUpload="onUpload"
      @onPublish="onPublish"
      class="fixed-bar"
    />
    <CMSBrowser
      v-if="action === 'browse'"
      :itemsTree="cmsItemsTree"
      :cmsItems="cmsItems"
      :activeItem="cmsId"
      @cmsBrowseRoute="cmsBrowseRoute"
      @cmsEditRoute="cmsEditRoute"
    />
    <CMSEditorToolbar
      v-if="action === 'edit'"
      :isDirty="isDirty"
      :isPublishing="isPublishing"
      :cmsSelectionItems="cmsSelectionItems"
      :cmsItem="cmsItem"
      class="fixed-bar"
      @cmsEditRoute="cmsEditRoute"
      @onNew="onNew"
      @onSaveArticle="onSaveArticle"
      @onDelete="onDelete"
      @onClose="onClose"
      @onPublish="onPublish"
    />
    <CMSEditorCk
      v-if="action === 'edit' && type === 'article'"
      :contentBody="contentBodyEdits"
      @onEditor="onEditor"
      :imageIndex="imageIndex"
    />
  </div>
</template>

<style scoped>
.fixed-bar {
  max-width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  padding: 0;
  z-index: 2;
}
</style>

<script>
import CMS from "@/api/cms/CMS";
import CMSBrowserToolbar from "@/components/CMSBrowserToolbar";
import CMSBrowser from "@/components/CMSBrowser";
import CMSEditorToolbar from "@/components/CMSEditorToolbar";
import CMSEditorCk from "@/components/CMSEditorCk";

export default {
  name: "CMSManager",
  components: {
    CMSBrowserToolbar,
    CMSBrowser,
    CMSEditorToolbar,
    CMSEditorCk,
  },
  props: {
    action: {
      default: "browse",
      type: String,
    },
    type: {
      default: null,
      type: String,
    },
    cmsId: {
      default: null,
      type: String,
    },
  },
  computed: {
    isDirty: function () {
      return this.contentBody !== this.contentBodyEdits;
    },
    isCmsItemDirty: function () {
      return this.cmsItem !== this.cmsItemEdits;
    },
    actionId: function () {
      console.log("ACTION ID COMPUTED");
      console.log(`${this.action}|${this.type}|${this.cmsId}`);
      return `${this.action}|${this.type}|${this.cmsId}`;
    },
  },
  async mounted() {
    console.log(`CMSManager: Mounted`);
    this.cmsMgr = new CMS();
    await this.cmsMgr.init();
    this.imageIndex = this.cmsMgr.getIndex("image");
    this.cmsItemsTree = this.cmsMgr.createCmsTree();
    await this.onActionIdChange();
    console.log("---------Begin CMSManager Render--------");
    this.render = true;
  },
  watch: {
    async actionId() {
      console.log("WATCHING actionId");
      console.log(this.actionId);
      // console.log(this.cmsId);
      await this.onActionIdChange();
    },
  },
  data: () => ({
    render: false,
    cmsMgr: null,
    cmsItemsTree: [],
    cmsItem: null,
    cmsItems: [],
    cmsSelections: [],
    contentBody: null,
    cmsItemEdits: null,
    contentBodyEdits: null,
    contentTitleEdits: null,
    imageIndex: null,
    isPublishing: false,
  }),
  methods: {
    async onActionIdChange() {
      console.log(`CMSManager onActionIdChange`);
      console.log(`${this.actionId}`);
      // Get the type, then do a find
      // cmsId could be: 1. null; 2. folder; 3. item

      this.cmsItem = await this.cmsMgr.read(this.type, this.cmsId);
      // Set a default type for the editor list
      // TODO: replace this default with type selector in toolbar
      let type = this.type ? this.type : "article";
      this.cmsSelectionItems = this.cmsMgr.list(type);
      this.cmsItemEdits = this.cmsItem;
      console.log("cmsItem");
      console.log(this.cmsItem);
      if (this.cmsItem === null) {
        console.log("onActionIdChange -> null");
        this.cmsItems = [];
        this.contentBody = null;
        this.contentBodyEdits = null;
      } else if (this.cmsItem.isDir) {
        console.log("onActionIdChange -> isDir");
        this.cmsItems = this.cmsMgr.list(this.cmsItem.type);
        this.contentBody = null;
        this.contentBodyEdits = null;
      } else if (this.cmsItem.id === "NoSuchId") {
        console.log("onActionIdChange -> NoSuchId");
        this.cmsBrowseRoute();
      } else {
        console.log("onActionIdChange -> item");
        this.cmsItems = [this.cmsItem];
        if (this.action === "edit") {
          this.contentBody = await this.cmsMgr.readContent(
            this.cmsItem.type,
            this.cmsItem.id
          );
          // TODO: Add NotAuthorizedException Error handling
          this.contentBodyEdits = this.contentBody;
        }
      }
    },

    async onNew(type) {
      console.log(`CMSManager: onNew`);
      console.log({ type });
      let name = "Untitled Document";
      let item = await this.cmsMgr.create(type, name);
      this.cmsEditRoute(item);
    },
    async onSaveArticle() {
      console.log(`CMSManager: onSaveArticle`);
      console.log(this.actionId);

      var parser = new DOMParser();
      var htmlDoc = parser.parseFromString(this.contentBodyEdits, "text/html");
      const featuturedFigure = htmlDoc.querySelector(
        '[data-is-featured="true"]'
      );

      let featuredImg = null;
      if (featuturedFigure !== null) {
        featuredImg = featuturedFigure.firstElementChild;
      }
      this.cmsItemEdits.imageSrc = featuredImg ? featuredImg.src : null;
      this.cmsItemEdits.imageSrcset = featuredImg ? featuredImg.srcset : null;

      const currentId = this.cmsId;
      try {
        this.cmsItem = await this.cmsMgr.update(
          this.type,
          this.cmsId,
          this.cmsItemEdits,
          this.contentBodyEdits,
          this.contentTitleEdits
        );
        this.contentBody = this.contentBodyEdits;
        if (this.cmsItem.id !== currentId) {
          // Handles change in Title which changes cmsId
          // TODO: do this in cmsMgr?
          this.cmsItemsTree = this.cmsMgr.createCmsTree();

          this.cmsEditRoute(this.cmsItem);
        }
      } catch (err) {
        console.error(err);
      }
    },
    onClose() {
      console.log("CMSManager: onClose");
      if (this.contentBodyEdits !== this.contentBody) {
        let close = confirm("Close without Saving?");
        if (close) {
          try {
            this.$router.push({
              name: "CMSManager",
              params: {
                action: "browse",
                type: this.type,
                cmsId: this.cmsId,
              },
            });
          } catch (err) {
            console.log({ err });
          }
        }
      }
    },
    onEditor(editedContent, editedTitle) {
      console.log(`CMSManager onEditor`);
      // console.log({ editedContent });
      // console.log({ editedTitle });
      this.contentBodyEdits = editedContent;
      this.contentTitleEdits = editedTitle;
    },
    onUpload(e) {
      console.log("CMSManager: onUpload");
      console.log({ e });
    },
    async onDelete(item) {
      console.log("CMSManager: onDelete");
      console.log({ item });
      if (confirm(`Delete "${item.bucket}/${item.contentKey}" ?`)) {
        try {
          await this.cmsMgr.delete(item);
          this.cmsItemsTree = this.cmsMgr.createCmsTree();
          this.cmsBrowseRoute();
        } catch (err) {
          console.log({ err });
        }
      }
    },
    cmsBrowseRoute(item = null) {
      //TODO: fix initializing activeItem in CMSBorwserTree
      // - dup nav error when selecting footer CMS,
      // - then selecting last activeItem in Tree (turning off)
      console.log("CMSManager: cmsBrowseRoute");
      console.log({ item });
      let cmsId = item ? item.id : null;
      let type = item ? item.type : null;
      try {
        this.$router.push({
          name: "CMSManager",
          params: {
            action: "browse",
            type: type,
            cmsId: cmsId,
          },
        });
      } catch (err) {
        console.log({ err });
      }
    },
    cmsEditRoute(item = null) {
      console.log("CMSManager: cmsEditRoute");
      console.log({ item });
      let id = item ? item.id : null;
      let type = item ? item.type : null;
      this.$router.push({
        name: "CMSManager",
        params: {
          action: "edit",
          type: type,
          cmsId: id,
        },
      });
    },
    async onPublish() {
      console.log("CMSManager: onPublish");
      let publish = true;
      if (this.isDirty) {
        publish = confirm("Save before Publishing?");
        if (publish) {
          await this.onSaveArticle();
        }
      }
      if (publish) {
        this.isPublishing = true;
        await this.cmsMgr.publish();
        this.isPublishing = false;
      }
      await this.cmsMgr.invalidateCache(
        this.cmsMgr.getIndexFolderName(this.type),
        this.cmsId
      );
    },
  },
};
</script>