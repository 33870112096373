import CFG from "@/api/cms/CMS_Config";
import CMS_Index from "@/api/cms/CMS_Index";
import CMS_Item_Image from "@/api/cms/CMS_Item_Image";

export default class CMS_Index_Image extends CMS_Index {
  constructor(s3Client, lambdaClient) {
    super(
      s3Client,
      CFG.cms.contentTypes.image.indexBucket,
      CFG.cms.contentTypes.image.indexKey
    );
    this.lambdaClient = lambdaClient;
  }

  async uploadImage(file) {
    console.log("CMS_Index_Image uploadImage");
    let imageItem = new CMS_Item_Image(file.name);

    imageItem.s3PutResult = await this.s3Client.put(
      imageItem.bucket,
      imageItem.contentKey,
      file
    );

    imageItem = await this.lambdaClient.imageProcessor(imageItem);
    await super.update(imageItem);

    console.log({ imageItem });
    return imageItem;
  }
}
