<template>
  <v-container fluid>
    <v-treeview
      v-model="tree"
      item-key="key"
      item-text="name"
      :items="items"
      :active="active"
      open-all
      activatable
      @update:active="onActive"
      dense
      return-object
      hoverable
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="item.isDir" color="yellow darken-1">
          {{ open ? "mdi-folder-open" : "mdi-folder" }}
        </v-icon>
        <v-icon v-else color="blue lighten-1">
          {{ fileExtIcons[item.ext].icon }}
        </v-icon>
      </template>
    </v-treeview>
  </v-container>
</template>

<script>
//      @input="onTreeSelect"
//      @update:open="cmsEditRoute"

import APP from "../appConfig";

export default {
  async mounted() {
    console.log(`FileManagerDirTree Mounted`);
    //console.log(this.initActive);
    //    this.active = this.initActive;
  },
  props: ["items", "initActive"],
  watch: {
    initActive() {
      console.log(`FileManagerDirTree Watch initActive`);
      //console.log(this.initActive);
      //      if (this.initActive !== this.active) this
    },
  },
  data: () => ({
    tree: [],
    value: [],
    active: [],
    fileExtIcons: APP.FileExtIcons,
  }),
  methods: {
    onActive(item) {
      console.log("onActive");
      console.log({ item });
      this.$emit("onActive", item);
    },
  },
};
</script>
