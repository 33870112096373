const slugify = require("slugify");
import CFG from "@/api/cms/CMS_Config";

export default class CMS_Item {
  constructor(name, type) {
    this.name = name;
    this.type = type;
    this.slug = slugify(name, {
      replacement: "-", // replace spaces with replacement character
      remove: undefined, // remove characters that match regex
      lower: false, // convert to lower case
      strict: false, // (false) strip special characters except replacement
      locale: "vi", // language code of the locale to use
      trim: true, // trim leading and trailing replacement chars
    });
    this.id = `${this.type}-${this.slug}`;
    this.url = `${this.type}-${this.slug}/`;
    this.prefix =
      this.type === "article"
        ? CFG.cms.contentTypes.article.prefix
        : CFG.cms.contentTypes.image.prefix;
    this.contentKey = `${this.prefix}/${this.id}.html`;
    this.isDir = false;
    this.dateCreated = Date.now();
    this.dateModified = Date.now();
    this.createdBy = null;
    this.modifiedBy = null;
    this.s3SaveResult = null;
  }
}
