import { LambdaClient, InvokeCommand } from "@aws-sdk/client-lambda";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { authGetSession } from "@/api/ggiAuth";
import APP from "../appConfig";

export default class awsClientLambda {
  constructor() {
    console.log("awsClientLambda Constructor");
    this.cognitoIdentityClient = new CognitoIdentityClient({
      region: APP.AWS.REGION,
    });
    this.provider =
      "cognito-idp." +
      APP.AWS.REGION +
      ".amazonaws.com/" +
      APP.COGNITO.USER_POOL_ID;
    this.logins = {};
    this.lambdaClient = null;
  }

  async init() {
    // a client can be shared by different commands.
    console.log("awsClientLambda init");
    const cognitoIdentityClient = new CognitoIdentityClient({
      region: APP.AWS.REGION,
    });
    const provider =
      "cognito-idp." +
      APP.AWS.REGION +
      ".amazonaws.com/" +
      APP.COGNITO.USER_POOL_ID;
    const logins = {};

    let session = await authGetSession();
    logins[provider] = session.getIdToken().getJwtToken();

    let cred = fromCognitoIdentityPool({
      client: cognitoIdentityClient,
      identityPoolId: APP.COGNITO.IDENTITY_POOL_ID,
      logins: logins,
    });

    const config = {
      region: APP.AWS.REGION,
      credentials: cred,
    };
    try {
      this.lambdaClient = new LambdaClient(config);
      this.lambdaClient.middlewareStack.add(
        (next, context) => (args) => {
          console.log("LAMBDA MIDDLEWARE");
          console.log({ context });
          console.log({ args });
          // args.request.headers["Custom-Header"] = "value";
          return next(args);
        },
        {
          step: "build",
        }
      );

      return this.lambdaClient;
    } catch (err) {
      console.log({ err });
      console.error(`awsClientLambda init Error ${err}`);
      return err;
    }
  }

  async publish(site) {
    console.log(`awsClientLambda publish Site: ${site}`);

    // Invoking with type "Event" makes Async request
    const input = {
      FunctionName: APP.AWS.SSG_FUNCTION,
      //InvocationType: "Event",
      Payload: JSON.stringify({
        Site: site,
      }),
    };

    try {
      const command = new InvokeCommand(input);
      const response = await this.lambdaClient.send(command);
      console.log({ response });
      // var string = new TextDecoder().decode(response.Payload);
      // console.log({ string });
      // let jsonObj = JSON.parse(string);
      // console.log({ jsonObj });

      return response;
    } catch (err) {
      console.error(`awsClientLambda publish Error: ${err}`);
      throw err;
    }
  }

  async imageProcessor(item) {
    console.log(`awsClientLambda imageProcessor`);

    const input = {
      FunctionName: APP.AWS.IMG_FUNCTION,
      Payload: JSON.stringify({
        item: item,
      }),
    };
    console.log({ input });

    try {
      const command = new InvokeCommand(input);
      const response = await this.lambdaClient.send(command);
      var string = new TextDecoder().decode(response.Payload);
      let imgurls = JSON.parse(string);
      console.log({ imgurls });
      return imgurls;
    } catch (err) {
      console.error(`awsClientLambda imageProcessorr: ${err}`);
      throw err;
    }
  }
}
