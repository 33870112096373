<template>
  <v-container>
    <v-toolbar dense>
      <v-btn @click="onNew" color="green" small rounded text>
        New Article</v-btn
      >
      <v-btn @click="onDelete" color="green" small rounded text>
        Delete Article</v-btn
      >

      <v-spacer></v-spacer>

      <v-autocomplete
        v-model="selectedItem"
        @input="onSelectFile"
        :loading="loading"
        item-text="name"
        return-object
        :items="cmsSelectionItems"
        :search-input.sync="search"
        cache-items
        class="mx-4"
        flat
        dense
        hide-no-data
        hide-details
        placeholder="Select a File to Edit"
      ></v-autocomplete>

      <v-spacer></v-spacer>

      <PublishButton
        :isPublishing="isPublishing"
        @onPublish="onPublish"
      ></PublishButton>
      <v-btn @click="onSaveArticleClick" icon v-bind:disabled="!isDirty">
        <v-icon color="green">mdi-content-save</v-icon>
      </v-btn>
      <v-btn @click="onCloseClick" icon v-bind:disabled="!isDirty">
        <v-icon color="red darken-1">mdi-close </v-icon>
      </v-btn>
    </v-toolbar>
  </v-container>
</template>

<script>
import PublishButton from "@/components/PublishButton";
export default {
  name: "CMSEditorToolbar",
  components: { PublishButton },

  async mounted() {
    console.log(`CMSEditorToolbar: Mounted`);
    this.items = [...this.cmsSelectionItems];
    //    this.selectedItem = this.cmsItem;
  },
  props: ["isDirty", "isPublishing", "cmsSelectionItems", "cmsItem"],
  computed: {
    showEdit: function () {
      return this.cmsItem !== null && !this.cmsItem.isDir;
    },
    selectedItem: function () {
      return this.cmsItem;
    },
  },
  data: () => ({
    items: [],
    selectedKey: null,
    search: null,
    loading: false,
    //selectedItem: null,
  }),
  watch: {
    search(val) {
      val && val !== this.selectedKey && this.querySelections(val);
    },
    selectedKey(selectedKey) {
      console.log("WATCHING selectedKey");
      console.log({ selectedKey });
      this.$emit("input", this.selectedKey);
    },
  },

  methods: {
    onSelectFile(item) {
      console.log("CMSEditorToolbar: onSelectFile");
      console.log({ item });
      this.$emit("cmsEditRoute", item);
    },
    onCloseClick() {
      console.log("CMSEditorToolbar: onCloseClick");
      this.$emit("onClose");
    },
    onSaveArticleClick() {
      console.log("CMSEditorToolbar: onSaveArticleClick");
      this.$emit("onSaveArticle");
    },
    onNew() {
      console.log("CMSEditorToolbar: onNew");
      this.$emit("onNew", "article");
    },
    onPublish() {
      console.log("CMSEditorToolbar: onPublish");
      this.$emit("onPublish");
    },
    onDelete() {
      console.log(`CMSBrowserToolbar: onDelete`);
      this.$emit("onDelete", this.cmsItem);
    },
    querySelections(searchVal) {
      this.loading = true;
      this.items = this.cmsSelectionItems.filter((key) => {
        return (
          (key.name || "")
            .toLowerCase()
            .indexOf((searchVal || "").toLowerCase()) > -1
        );
      });
      this.loading = false;
    },
  },
};
</script>
