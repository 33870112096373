import Vue from "vue";
import Router from "vue-router";
import Home from "@/components/Home.vue";
//import CMSEditor from "@/components/CMSEditor.vue";
//import ConfigManager from "@/components/ConfigManager.vue";
import FileManager from "@/components/FileManager.vue";
import CMSManager from "@/components/CMSManager.vue";
import AuthLogin from "@/components/AuthLogin.vue";
import AuthRegister from "@/components/AuthRegister.vue";
import AuthConfirm from "@/components/AuthConfirm.vue";
import AuthLogout from "@/components/AuthLogout.vue";
import { authGetSession } from "@/api/ggiAuth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { public: true },
    },
    {
      path: "/login",
      name: "login",
      component: AuthLogin,
      meta: { onlyWhenLoggedOut: true, public: true },
    },
    {
      path: "/register",
      name: "register",
      component: AuthRegister,
      meta: { onlyWhenLoggedOut: true, public: true },
    },
    {
      path: "/confirm",
      name: "confirm",
      component: AuthConfirm,
      meta: { onlyWhenLoggedOut: true, public: true },
    },
    { path: "/logout", name: "logout", component: AuthLogout },
    // {
    //   path: "/edit/:id?",
    //   name: "CMSEditor",
    //   component: CMSEditor,
    //   props: true,
    // },
    {
      path: "/file/:drive?/:path?/:activeItem?",
      name: "FileManager",
      component: FileManager,
      props: true,
    },
    {
      path: "/cms/:action?/:type?/:cmsId?",
      name: "CMSManager",
      component: CMSManager,
      props: true,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  console.log("Router: beforeEach");
  //console.log({ from });
  //console.log({ to });
  //console.log({ next });

  const currentUser = await authGetSession();
  console.log({ currentUser });

  const onlyWhenLoggedOut = to.matched.some(
    (record) => record.meta.onlyWhenLoggedOut
  );

  if (currentUser) {
    if (onlyWhenLoggedOut) {
      console.log("router (beforeEach): authenticated && onlyWhenLoggedOut");
      next("/");
    } else {
      console.log("router (beforeEach): authenticated");
      next();
    }
  } else {
    if (to.matched.some((record) => record.meta.public)) {
      console.log("router (beforeEach): NOT authenticated & publicMatched");
      next();
    } else {
      console.log("router (beforeEach): not authenticated - redirect to Login");
      next({ path: "/login", query: { redirect: to.fullPath } });
    }
  }
});

export default router;
