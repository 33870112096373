import {
  CloudFrontClient,
  CreateInvalidationCommand,
} from "@aws-sdk/client-cloudfront";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { authGetSession } from "@/api/ggiAuth";
import APP from "../appConfig";

export default class awsClientCloudFront {
  constructor() {
    this.cognitoIdentityClient = new CognitoIdentityClient({
      region: APP.AWS.REGION,
    });
    this.provider =
      "cognito-idp." +
      APP.AWS.REGION +
      ".amazonaws.com/" +
      APP.COGNITO.USER_POOL_ID;
    this.logins = {};
    this.cloudFrontClient = null;
  }

  async init() {
    console.log("awsClientCloudFront init");
    let session = await authGetSession();
    this.logins[this.provider] = session.getIdToken().getJwtToken();
    let cred = fromCognitoIdentityPool({
      client: this.cognitoIdentityClient,
      identityPoolId: APP.COGNITO.IDENTITY_POOL_ID,
      logins: this.logins,
    });
    try {
      this.cloudFrontClient = new CloudFrontClient({
        region: APP.AWS.REGION,
        credentials: cred,
      });
      this.cloudFrontClient.middlewareStack.add(
        (next, context) => (args) => {
          console.log("CloudFrontClient MIDDLEWARE");
          console.log({ context });
          console.log({ args });
          // args.request.headers["Custom-Header"] = "value";
          return next(args);
        },
        {
          step: "build",
        }
      );
    } catch (err) {
      console.error(`init Error ${err}`);
      throw err;
    }
  }

  async invalidateCache(params) {
    console.log(`awsClientCloudFront invalidateCache: `);

    try {
      let getRes = await this.cloudFrontClient.send(
        new CreateInvalidationCommand(params)
      );
      return getRes;
    } catch (err) {
      console.error(`get Error: ${err}`);
      return err;
      // throw err;
    }
  }
}
