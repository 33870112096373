import CFG from "@/api/cms/CMS_Config";
import CMS_Item from "@/api/cms/CMS_Item";

export default class CMS_Item_Article extends CMS_Item {
  constructor(name, cmsItem = {}) {
    super(name, CFG.cms.contentTypes.article.type);
    this.bucket = CFG.cms.contentTypes.article.bucket;
    //    this.prefix = CFG.cms.contentTypes.article.prefix;
    //    this.contentKey = `${this.prefix}/${this.id}.html`;
    this.authorId = null;
    this.title = cmsItem.title ? cmsItem.title : null;
    this.subtitle = cmsItem.subtitle ? cmsItem.subtitle : null;
    this.description = cmsItem.description ? cmsItem.description : null;
    this.seoTitle = cmsItem.seoTitle ? cmsItem.seoTitle : null;
    this.seoDescription = cmsItem.seoDescription
      ? cmsItem.seoDescription
      : null;
    this.tags = cmsItem.tags ? cmsItem.tags : [];
    this.imageSrc = cmsItem.imageSrc ? cmsItem.imageSrc : null;
    this.imageSrcset = cmsItem.imageSrcset ? cmsItem.imageSrcset : null;
  }
}
