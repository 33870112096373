<template>
  <v-toolbar dense>
    <v-btn @click="onChangeView('Gallery')" icon>
      <v-icon color="blue darken-1">mdi-image-multiple </v-icon>
    </v-btn>
    <v-btn @click="onChangeView('Details')" icon>
      <v-icon color="blue darken-1">mdi-view-list-outline </v-icon>
    </v-btn>

    <v-spacer></v-spacer>

    <v-chip @click="viewSelectionsClick" color="primary">
      Selections: {{ filesSelected.length }}
    </v-chip>

    <v-btn @click="onUploadClick" icon>
      <v-icon color="blue darken-1">mdi-upload </v-icon>
    </v-btn>
    <v-btn
      @click="onDownloadClick"
      icon
      v-bind:disabled="filesSelected.length === 0 ? true : false"
    >
      <v-icon color="blue darken-1">mdi-download </v-icon>
    </v-btn>
    <v-btn
      @click="onDeleteClick"
      icon
      v-bind:disabled="filesSelected.length === 0 ? true : false"
    >
      <v-icon color="red darken-1">mdi-delete </v-icon>
    </v-btn>

    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "FileToolbar",
  components: {},

  async mounted() {
    console.log(`FileToolbar: Mounted`);
    //console.log(this.activeItem);
    if (this.action === "edit") this.selectedFileKey = this.activeItem;
  },
  props: ["filesSelected", "isDirty", "fileList", "activeItem"],

  data: () => ({
    selectedKey: null,
    search: null,
    loading: false,
    viewSelect: ["Gallery"],
    viewItems: ["Gallery", "Details"],
    selectedFileKey: null,
  }),

  watch: {
    search(val) {
      val && val !== this.selectedKey && this.querySelections(val);
    },
    selectedKey() {
      console.log("WATCHING selectedKey");
      //console.log({ selectedKey });
      this.$emit("input", this.selectedKey);
    },
  },

  methods: {
    onDownloadClick() {
      console.log("FileToolbar: onDownloadClick");
      this.$emit("onDownload", this.filesSelected);
    },
    onSelectEditFileClick() {
      console.log("FileToolbar: onSelectEditFileClick");
      //console.log(this.selectedFileKey);
      this.$emit("onSelectEditFile", this.selectedFileKey);
    },
    onChangeView(e) {
      console.log("FileToolbar: onChangeView");
      //console.log({ e });
      this.$emit("onChangeView", e);
    },
    viewSelectionsClick() {
      console.log("FileToolbar: viewSelectionsClick");
    },
    onUploadClick() {
      console.log("FileToolbar: onUploadClick");
      console.log(this.selected);
      //this.$emit("onUpload", this.filesSelected);
    },
    onDeleteClick() {
      console.log("FileToolbar: onDeleteClick");
      console.log(this.selected);
      this.$emit("onDelete", this.filesSelected);
    },
  },
};
</script>
