<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-card class="my-10 elevation-12" width="374">
        <v-card-title>Register</v-card-title>
        <v-card-text v-if="error != null">
          {{ error.message }}
        </v-card-text>

        <v-card-text>
          <v-form>
            <v-text-field
              v-model="email"
              label="Email"
              prepend-icon="mdi-account"
              type="text"
            ></v-text-field>

            <v-text-field
              v-model="password"
              label="Password"
              prepend-icon="mdi-lock"
              type="password"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelClick">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="registerClick" color="primary">Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { authRegister } from "@/api/ggiAuth";

export default {
  name: "AuthRegister",
  data: () => {
    return {
      email: "mgleason09@gmail.com",
      password: "mike1234",
      error: null,
    };
  },
  methods: {
    async registerClick() {
      console.log("registerClick");
      try {
        let res = await authRegister({
          email: this.email,
          password: this.password,
        });
        console.log({ res });
        if (!res.userConfirmed) this.$router.replace("/confirm");
      } catch (err) {
        this.error = err;
        console.log(`Register Error: `, err);
      }
    },
    async cancelClick() {
      console.log("cancelClick");
      this.error = null;
      // TODO: Should go back to where we came from
      this.$router.replace("/");
    },
  },
};
</script>
