<template>
  <v-data-table
    :headers="headers"
    :items="items"
    show-select
    item-key="bfKey"
    class="elevation-1"
    dense
    fixed-header
    hide-default-footer
    disable-pagination
    height="500px"
    @input="onSelectFile"
    @dblclick:row="onDblclickRow"
  >
    <template v-slot:item.name="{ item }">
      <v-icon v-if="item.isDir" color="yellow darken-1">
        {{ item.icon }}
      </v-icon>
      <v-icon v-else color="blue lighten-1">
        {{ item.icon }}
      </v-icon>
      {{ item.name }}
    </template>
    <template v-slot:item.lastModified="{ item }">
      {{ item.lastModified ? item.lastModified.toDateString() : null }}
    </template>
  </v-data-table>
</template>

<script>
import APP from "../appConfig";
import { getImgUrl } from "@/api/imgUtil";

export default {
  name: "FileManagerViewDetails",
  components: {},
  props: ["items", "init"],
  data: () => ({
    selected: [],
    headers: [
      {
        text: "Name",
        value: "name",
      },
      { text: "Last Modified", value: "lastModified" },
      { text: "Size", value: "size" },
    ],
    fileExtIcons: APP.FileExtIcons,
  }),
  async mounted() {
    console.log("FileManagerViewDetails: Mounted");
    //console.log(this.items);
    //console.log(this.init);
    this.selected = [...this.init];
    //console.log(this.selected);
  },
  methods: {
    getImageUrl(key) {
      return getImgUrl(APP.S3.IMAGE_BUCKET, key, APP.IMG_SIZES["icon"]);
    },
    onSelectFile(fileItems) {
      console.log("FileManagerViewDetails onSelectFile");
      //console.log({ fileItems });
      this.$emit("onSelectFile", fileItems);
    },
    onDblclickRow(e, row) {
      console.log("FileManagerViewDetails onDblClickRow");
      //console.log({ e });
      //console.log({ row });
      this.$emit("cmsEditRoute", row.item);
    },
  },
};
</script>

<style scoped>
</style>