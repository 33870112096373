import APP from "../appConfig";

export default class File {
  constructor(bucket, prefix, item) {
    // console.log({ bucket });
    // console.log({ prefix });
    // console.log({ item });
    this._bucket = bucket;
    this._prefix = prefix;
    this._id = item.Key;
    this._key = item.Key;
    this._path = this.parsePath(this._key);
    this._name = this.parseName(this._key);
    this._parent = this.getParent(this._key);
    this._ext = this.parseExt(this._key);
    this._cmsType = "config";
    this._mimeType = this.getMimeType(this._ext);
    this._editor = this.getMimeEditor(this._ext);
    this._icon = this.getMimeIcon(this._ext);
    this._isDir = this.getIsDir(this._key);
    this._lastModified = item.LastModified;
    this._size = item.Size;
    this._owner = item.Owner;
    this._item = item;
  }

  get bucket() {
    return this._bucket;
  }
  get prefix() {
    return this._prefix;
  }
  get id() {
    return this._id;
  }
  get key() {
    return this._key;
  }
  get path() {
    return this._path;
  }
  get name() {
    return this._name;
  }
  get parent() {
    return this._parent;
  }
  get ext() {
    return this._ext;
  }
  get icon() {
    return this._icon;
  }
  get lastModified() {
    return this._lastModified;
  }
  get size() {
    return this._size;
  }
  get owner() {
    return this._owner;
  }
  get editor() {
    return this._editor;
  }
  get mimeType() {
    return this._mimeType;
  }
  get isDir() {
    return this._isDir;
  }

  parsePath(key) {
    // console.log("File parsePath");
    let path = key;
    if (this.prefix !== null) {
      if (this.prefix.length > 0) path = key.substring(this.prefix.length);
    }
    return path;
  }

  parseName(key) {
    // using S3, so MAC scenario does not apply
    // leaving comments for awareness if getting files from disk
    let name = "";
    // If root, then name is bucket/prefix
    if (key === "/") {
      name = this._bucket;
      if (this._prefix !== null) name = name + "/" + this._prefix;
    } else {
      if (key.endsWith("/")) key = key.substring(0, key.length - 1);
      //if (key.endsWith("\\")) key = key.substring(0, key.length - 2);
      // Should work on both Windows and Mac
      name = key
        // .split("\\")
        // .pop()
        .split("/")
        .pop();
    }
    return name;
  }

  parseExt(key) {
    // If a.length is one, it's a visible file with no extension ie. file
    // If a[0] === "" and a.length === 2 it's a hidden file with no extension ie. .htaccess
    // console.log("File parseExt");

    let a = key.split(".");
    if (a.length === 1 || (a[0] === "" && a.length === 2)) {
      return "";
    }
    return a.pop().toLowerCase();
  }
  getBucket(id) {
    return APP.FM.DRIVES[id].bucket;
  }
  getPrefix(id) {
    return APP.FM.DRIVES[id].prefix;
  }
  getMimeType(ext) {
    // console.log("File getMimeType");
    return APP.MIME_TYPES[ext]
      ? APP.MIME_TYPES[ext]
      : "application/octet-stream";
  }

  getMimeEditor(ext) {
    console.log(`File getMimeEditor ${ext}`);
    let editor = ext === "" ? "" : APP.FileExtEditor[ext].editor;
    return editor;
  }

  getMimeIcon(ext) {
    // console.log("File getMimeIcon");
    let icon = ext === "" ? "" : APP.FileExtIcons[ext].icon;
    return icon;
  }

  getParent(key) {
    if (key.endsWith("/")) key = key.substring(0, key.length - 1);
    //if (key.endsWith("\\")) key = key.substring(0, key.length - 2);
    let parent =
      key.lastIndexOf("/") > 0 ? key.slice(0, key.lastIndexOf("/")) + "/" : "/";
    return parent;
  }

  getIsDir(key) {
    return key.endsWith("/");
  }

  // toString() {
  //   var obj = JSON.parse(this);
  //   return JSON.stringify(obj, null, 2);
  // }
}
