<template>
  <v-footer color="primary" padless app>
    <v-row justify="space-around" no-gutters>
      <v-btn
        v-for="link in links"
        :key="link.title"
        color="white"
        text
        rounded
        :to="link.route"
        class="yellow--text"
        >{{ link.title }}</v-btn
      >
    </v-row>
  </v-footer>
</template>

<style scoped>
#nav-link:hover,
#nav-link.router-link-active,
#nav-link.router-link-exact-active {
  background-color: indianred;
  cursor: pointer;
}
</style>

<script>
export default {
  name: "NavFooter",

  // async mounted() {
  //   console.log("NavFooter: Mounted");
  //   console.log(this.links);
  // },
  props: ["links"],

  data: () => ({
    showModalInput: false,
  }),

  methods: {
    onAddClick() {
      this.showModalInput = true;
    },
  },
};
</script>
