const CMS_Config = {
  site: {
    domain: "gleasongroup.club",
    subdomains: ["images", "admin", "mike", "ryan"],
    distributionId: "E7PFH7S77SR8W",
  },
  cms: {
    bucket: "gleasongroup.club-cms",
    // config: {
    //   name: "config",
    //   bucket: "gleasongroup.club-cms",
    //   prefix: "src/_data/config",
    //   ext: "json",
    // },
    // menu: {
    //   name: "menu",
    //   bucket: "gleasongroup.club-cms",
    //   prefix: "src/_data/menu",
    //   ext: "json",
    // },
    // },
    contentTypes: {
      article: {
        type: "article",
        bucket: "gleasongroup.club-cms",
        prefix: "content/src/articles",
        folderName: "articles",
        indexBucket: "gleasongroup.club-cms",
        indexKey: "content/src/_data/articles.json",
        indexName: "articles",
      },
      image: {
        type: "image",
        bucket: "gleasongroup.club",
        prefix: "content/src/images",
        folderName: "images",
        subdomain: "images",
        indexBucket: "gleasongroup.club-cms",
        indexKey: "content/src/_data/images.json",
        indexName: "images",
        imageUrl: "https://images.gleasongroup.club",
        originalImageFolder: "orig",
        sizes: {
          default: { resize: { width: 1920, fit: "inside" } },
          "1920": { resize: { width: 1920, fit: "inside" } },
          "1600": { resize: { width: 1600, fit: "inside" } },
          "1400": { resize: { width: 1400, fit: "inside" } },
          "1100": { resize: { width: 1100, fit: "inside" } },
          "800": { resize: { width: 800, fit: "inside" } },
          "640": { resize: { width: 640, fit: "inside" } },
          "360": { resize: { width: 360, fit: "inside" } },
          "150": { resize: { width: 150, fit: "inside" } },
          "50": { resize: { width: 50, fit: "inside" } },
        },
      },
    },
  },
};

export default CMS_Config;
