export default class CMS_Index {
  constructor(s3Client, bucket, key) {
    this.s3Client = s3Client;
    this.bucket = bucket;
    this.key = key;
    this.index = {};
  }

  // Functions
  //  - index:    init
  //  - item:     read, update, delete, list
  //  - private:  updateIndex

  async init() {
    console.log(`CMS_Index init`);
    try {
      const idx = await this.s3Client.get(this.bucket, this.key);
      if (idx.name === "NoSuchKey") {
        console.log("NoSuckKey: Create New Index");
        this.index = {};
        this.updateIndex();
      } else {
        this.index = JSON.parse(idx);
      }
    } catch (e) {
      // TODO: Check for 404 error if index not found
      console.log({ e });
    }
  }

  async update(item) {
    console.log(`CMS_Index update`);
    // console.log({ item });
    if (item.id !== null) {
      try {
        this.index[item.id] = item;
        await this.updateIndex();
      } catch (err) {
        console.log(`CMS_Index update: ${err}`);
        console.log({ item });
        throw err;
      }
    } else {
      return null;
    }
  }

  async delete(cmsItem) {
    let id = cmsItem && cmsItem.id ? cmsItem.id : null;
    console.log(`CMS_Index delete ${id}`);
    if (id !== null) {
      try {
        await this.s3Client.delFile(this.bucket, this.index[id].contentKey);
        delete this.index[id];
        await this.updateIndex();
      } catch (err) {
        console.log(
          `CMS_Index_Article delFile: Error deleting Id: ${id}; Error: ${err}`
        );
        throw err;
      }
    } else {
      return null;
    }
  }

  read(id) {
    // Return an id of "NoSuchId" if Not Found
    console.log(`CMS_Index read: ${id}`);
    let item = null;
    if (id === null) item = null;
    else if (id in this.index) {
      item = this.index[id];
    } else item = { id: "NoSuchId" };
    return item;
  }

  list() {
    console.log(`CMS_Index list`);
    if (this.index === {}) return [];
    let x = Object.values(this.index);
    console.log({ x });
    return x;
  }

  // Private Functions

  async updateIndex() {
    console.log("CMS_Index updateIndex");
    // console.log(this.index);
    try {
      const s3ClientPutDirResult = await this.s3Client.put(
        this.bucket,
        this.key,
        JSON.stringify(this.index)
      );
      // console.log(
      //   `CMS_Index update: Index ${this.key} updated in bucket ${this.bucket}`
      // );
      console.log({ s3ClientPutDirResult });
    } catch (err) {
      console.log("CMS_Index update: Error");
      console.log({ err });
    }
  }
}
