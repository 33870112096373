import APP from "../appConfig";

function getImgUrl(bucketName, keyName, edits) {
  const request = {
    bucket: bucketName,
    key: keyName,
  };

  if (edits !== null) request.edits = edits;

  // Setup encoded request
  const str = JSON.stringify(request);
  const enc = btoa(str);

  //  console.log(JSON.stringify(request, undefined, 2));
  //  console.log(`${APP.CDN.IMAGE_HANDLER}/${enc}`);

  // Return CDN URL for Image
  return `${APP.S3.IMAGE_BUCKET}/${APP.AWS.SITE_NAME}/${enc}`;
}

function getImgUrls(bucket, key) {
  const urls = {};
  for (var size in APP.IMG_SIZES) {
    urls[size] = getImgUrl(bucket, key, APP.IMG_SIZES[size]);
  }
  console.log({ urls });
  return urls;
}

export { getImgUrl, getImgUrls };
// function hexToRgbA(hex, _alpha) {
//   var c;
//   if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
//     c = hex.substring(1).split("");
//     if (c.length == 3) {
//       c = [c[0], c[0], c[1], c[1], c[2], c[2]];
//     }
//     c = "0x" + c.join("");
//     return {
//       r: (c >> 16) & 255,
//       g: (c >> 8) & 255,
//       b: c & 255,
//       alpha: Number(_alpha),
//     };
//   }
//   throw new Error("Bad Hex");
// }

// function resetEdits() {
//   $(".form-control").val("");
//   document.getElementById("editor-resize-mode").selectedIndex = 0;
//   $(".form-check-input").prop("checked", false);
// }

// EDIT FIELDS

// if (_resize !== "Disabled") {
//   if (_width !== "") {
//     _edits.resize.width = Number(_width);
//   }
//   if (_height !== "") {
//     _edits.resize.height = Number(_height);
//   }
//   _edits.resize.fit = _resize;
// }
// if (_fillColor !== "") {
//   _edits.resize.background = hexToRgbA(_fillColor, 1);
// }
// if (_backgroundColor !== "") {
//   _edits.flatten = { background: hexToRgbA(_backgroundColor, undefined) };
// }
// if (_grayscale) {
//   _edits.grayscale = _grayscale;
// }
// if (_flip) {
//   _edits.flip = _flip;
// }
// if (_flop) {
//   _edits.flop = _flop;
// }
// if (_negative) {
//   _edits.negate = _negative;
// }
// if (_flatten) {
//   _edits.flatten = _flatten;
// }
// if (_normalize) {
//   _edits.normalise = _normalize;
// }
// if (_rgb !== "") {
//   const input = _rgb.replace(/\s+/g, "");
//   const arr = input.split(",");
//   const rgb = { r: Number(arr[0]), g: Number(arr[1]), b: Number(arr[2]) };
//   _edits.tint = rgb;
// }
// if (_smartCrop) {
//   _edits.smartCrop = {};
//   if (_smartCropIndex !== "") {
//     _edits.smartCrop.faceIndex = Number(_smartCropIndex);
//   }
//   if (_smartCropPadding !== "") {
//     _edits.smartCrop.padding = Number(_smartCropPadding);
//   }
// }
// if (Object.keys(_edits.resize).length === 0) {
//   delete _edits.resize;
// }
