<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-card class="my-10 elevation-12" width="374">
        <v-card-title>Logout</v-card-title>
        <v-card-text v-if="error != null">
          {{ error.message }}
        </v-card-text>

        <v-card-text>
          <v-text-field
            v-model="username"
            label="Username"
            prepend-icon="mdi-account"
            type="text"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelClick">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="logoutClick" color="primary">Logout</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { authLogout } from "@/api/ggiAuth";

export default {
  name: "AuthLogout",
  data: () => {
    return {
      username: "gleason.mike@live.com",
      error: null,
    };
  },
  methods: {
    logoutClick() {
      authLogout();
      // TODO: go back to where we came from?
      this.$router.replace("/");
    },
    async cancelClick() {
      console.log("cancelClick");
      this.error = null;
      this.$router.back();
    },
  },
};
</script>
