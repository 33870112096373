<template>
  <v-btn
    @click="publishAction"
    :loading="isPublishing"
    :disabled="isPublishing"
    color="green"
    small
    rounded
    text
  >
    Publish
  </v-btn>
</template>

<style scoped>
</style>

<script>
//import awsClientLambda from "@/api/awsClientLambda";

export default {
  name: "PublishButton",
  props: ["isPublishing"],

  methods: {
    async publishAction(e) {
      console.log("PublishButton: publishAction");
      console.log({ e });
      this.$emit("onPublish");
    },
  },
  data: () => ({
    // render: false,
    // lambdaClient: null,
    publishing: false,
  }),
};
</script>
