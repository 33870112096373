<template>
  <v-container fluid class="container">
    <FileToolbar
      v-if="render"
      :filesSelected="filesSelected"
      :activeItem="activeItem"
      @onNew="onNew"
      @cmsEditRoute="cmsEditRoute"
      @onRefresh="onRefresh"
      @onDelete="onDelete"
      @onDownload="onDownload"
      @onUpload="onUpload"
      @onView="onView"
      class="fixed-bar"
    />
    <FileBrowser
      v-if="render"
      :items="items"
      :drives="drives"
      :activeItem="activeItem"
      @onSelectDir="onSelectDir"
      @onSelectFile="onSelectFile"
      @cmsEditRoute="cmsEditRoute"
    />
  </v-container>
</template>

<script>
import awsClientS3 from "@/api/awsClientS3";
import Drive from "@/api/Drive";
import { getMimeApp } from "@/api/fmUtil";
import FileToolbar from "@/components/FileToolbar";
import FileBrowser from "@/components/FileBrowser";
import APP from "../appConfig";
import { getImgUrls } from "@/api/imgUtil";

let s3Client = new awsClientS3();

export default {
  name: "FileManager",
  components: {
    FileToolbar,
    FileBrowser,
  },
  props: {
    action: {
      default: "browse",
      type: String,
    },
    drive: {
      default: "",
      type: String,
    },
    path: {
      default: "",
      type: String,
    },
    activeItem: {
      default: "root",
      type: String,
    },
  },
  async mounted() {
    console.log(
      `FileManager: Mounted with Drive: ${this.drive} Path: ${this.path} and Active: ${this.activeItem}`
    );
    await s3Client.init();

    // Get the current directory listing
    let listContents = this.drive
      ? await s3Client.driveListContents(this.drive, this.path, "/")
      : [];
    this.items =
      listContents.length > 0
        ? s3Client.createFiles("gleasongroup.club-cms", null, listContents)
        : [];

    this.drives = [];
    for (const drive in APP.FM.DRIVES) {
      let s3ListContents = await s3Client.driveListContents(drive);
      //console.log({ s3ListContents });
      this.drives.push(new Drive(drive, s3ListContents));
    }
    this.render = true;
  },
  watch: {
    path: async function () {
      console.log(`WATCH FileManager: drive ${this.drive} path ${this.path}`);
      // Get the current directory listing
      let listContents = this.drive
        ? await s3Client.driveListContents(this.drive, this.path, "/")
        : [];
      this.items =
        listContents.length > 0
          ? s3Client.createFiles("gleasongroup.club-cms", null, listContents)
          : [];
      console.log(this.items);
    },
  },
  data: () => ({
    render: false,
    fileList: [],
    items: [],
    dirTree: [],
    drives: [],
    filesSelected: [],
    editor: null,
  }),
  methods: {
    onNew(e) {
      console.log("FileManager: onNew");
      console.log({ e });
    },
    onRefresh(e) {
      console.log("FileManager: onRefresh");
      console.log({ e });
    },
    onView(e) {
      console.log("FileManager: onView");
      console.log({ e });
    },
    onUpload(e) {
      console.log("FileManager: onUpload");
      console.log({ e });
    },
    onSelectFile(files) {
      console.log("FileManager: onSelectFile");
      console.log({ files });
      this.filesSelected = files;
    },
    onSelectDir(folder) {
      console.log("FileManager: onSelectDir");
      console.log({ folder });
      try {
        this.$router.push({
          name: "FileManager",
          params: {
            drive: folder[0].drive,
            path: folder[0].path,
          },
        });
      } catch (err) {
        console.log({ err });
      }
    },
    async onDeleteFile(items) {
      // TODO: Create multiple file delete in S3Util
      //       Create refresh folder listing routine (mounted, watch, delete)
      //       Refresh itemTree if deleting directory
      console.log("FileManager: onDelete");
      console.log({ items });
      if (confirm(`Delete "${items[0].key}" ?`)) {
        try {
          await s3Client.delFile(items[0].drive, items[0].key);
          let listContents = await s3Client.driveListContents(
            items[0].drive,
            null,
            "/"
          );
          this.items =
            listContents.length > 0
              ? s3Client.createFiles(
                  "gleasongroup.club-cms",
                  null,
                  listContents
                )
              : [];

          this.filesSelected = [];
        } catch (err) {
          console.log({ err });
        }
      }
    },
    cmsEditRoute(item) {
      console.log("FileManager: cmsEditRoute");
      console.log(item);
      let appName = getMimeApp(item.key);
      if (item.isDir) appName = "FileManager";
      else appName = "FileEditor";
      this.$router.push({
        name: appName,
        params: {
          drive: item.drive,
          path: item.path,
        },
      });
    },
    async onDownload(e) {
      console.log("FileManager: onDownload");
      // if (confirm(`Download File "${e}" ?`)) {
      //   //let data = await s3GetObject2(APP.S3.IMAGE_drive, e[0]);
      //   //        downloadUrlToFile("pic.jpg");
      //   this.filesSelected = [];
      // }
      console.log({ e });
      let imgUrls = getImgUrls(e[0].drive, e[0].path);
      console.log({ imgUrls });
    },
    async onDelete(drive, dir) {
      //const albumKey = encodeURIComponent(albumName) + "/";
      try {
        const data = await s3Client.driveListContents(drive, dir);
        const objects = data.map(function (object) {
          return { Key: object.Key };
        });
        await s3Client.delFiles(drive, objects);
        let listContents = await s3Client.driveListContents(drive, null, "/");
        this.items =
          listContents.length > 0
            ? s3Client.createFiles("gleasongroup.club-cms", null, listContents)
            : [];
      } catch (err) {
        return alert("There was an error deleting your album: ", err.message);
      }
    },
  },
};
</script>

<style scoped>
.fixed-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  z-index: 2;
  margin-bottom: 1rem;
}
.container {
  padding: 0;
}
</style>
