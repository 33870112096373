<template>
  <v-treeview
    v-model="selected"
    :items="itemsTree"
    item-key="id"
    item-text="name"
    open-all
    activatable
    :active="active"
    @update:active="onActive"
    dense
    return-object
    hoverable
  >
    <template v-slot:prepend="{ item, open }">
      <v-icon v-if="item.isDir" color="yellow darken-1">
        {{ open ? "mdi-folder-open" : "mdi-folder" }}
      </v-icon>
      <v-icon v-else color="blue lighten-1">
        {{ item.icon }}
      </v-icon>
    </template>
  </v-treeview>
</template>

<script>
import APP from "../appConfig";

export default {
  name: "CMSBrowserTree",
  components: {},
  props: ["itemsTree", "activeItem"],
  data: () => ({
    selected: [],
    active: [],
    fileExtIcons: APP.FileExtIcons,
    // render: false,
  }),
  // mounted() {
  //   console.log("CMSBrowserTree: Mounted");
  //   this.render = true;
  // },
  methods: {
    onActive(selections) {
      console.log("CMSBrowserTree: onActive");
      // console.log({ selections });
      // console.log(this.itemsTree);
      this.$emit("onActive", selections);
    },
  },
};
</script>

<style scoped>
</style>
