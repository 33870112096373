<template>
  <v-toolbar dense>
    <v-btn @click="onNew" color="green" small rounded text> New Article</v-btn>
    <v-btn @click="onDelete" color="green" small rounded text>
      Delete Article</v-btn
    >
    <v-btn v-if="showEdit" @click="onEdit" color="green" small rounded text>
      Edit
    </v-btn>
    <v-spacer></v-spacer>

    <PublishButton
      :isPublishing="isPublishing"
      @onPublish="onPublish"
    ></PublishButton>

    <v-btn icon>
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import PublishButton from "@/components/PublishButton";

export default {
  name: "CMSBrowserToolbar",
  components: { PublishButton },

  async mounted() {
    console.log(`CMSBrowserToolbar: Mounted`);
  },
  props: ["cmsId", "isDirty", "isPublishing", "cmsItems", "cmsItem"],

  data: () => ({
    loading: false,
  }),
  computed: {
    showEdit: function () {
      return this.cmsItem !== null && !this.cmsItem.isDir;
    },
  },

  methods: {
    onEdit() {
      console.log("CMSBrowserToolbar: onEdit");
      this.$emit("cmsEditRoute", this.cmsItem);
    },
    onNew() {
      console.log(`CMSBrowserToolbar: onNew`);
      this.$emit("onNew", "article");
    },
    onPublish() {
      console.log(`CMSBrowserToolbar: onPublish`);
      this.$emit("onPublish");
    },
    onDelete() {
      console.log(`CMSBrowserToolbar: onDelete`);
      this.$emit("onDelete", this.cmsItem);
    },
  },
};
</script>
