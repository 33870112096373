<template>
  <v-card class="mx-auto my-12" max-width="374">
    <v-img :src="item.imageSrc" :srcset="item.imageSrcset"></v-img>

    <v-card-title>{{ item.name }}</v-card-title>

    <v-card-text>
      <div class="my-4 text-subtitle-1">Subtitle</div>

      <div>
        Small plates, salads & sandwiches - an intimate setting with 12 indoor
        seats plus patio seating.
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <v-btn color="deep-purple lighten-2" small rounded text @click="onEdit">
        Edit
      </v-btn>
      <v-btn color="deep-purple lighten-2" text @click="onEdit">
        Preview
      </v-btn>
      <v-spacer />
      <v-btn color="deep-purple lighten-2" text @click="onEdit">
        Publish
      </v-btn>
      <v-btn color="deep-purple lighten-2" text @click="onEdit"> Live </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "CMSItemCard",
  props: ["item"],
  data: () => ({}),
  mounted() {
    console.log("CMSItemCard: Mounted");
    console.log(this.item);
  },
  methods: {
    onEdit(item) {
      console.log("CMSItemCard: onEdit");
      console.log(item);
      this.$emit("cmsEditRoute", this.item);
    },
  },
};
</script>