<template>
  <v-container class="imageText">
    <v-row>
      <v-col
        v-for="item in items"
        :key="item.Key"
        class="d-flex child-flex"
        cols="4"
      >
        <v-card>
          <v-checkbox
            v-model="selected"
            :value="item"
            :label="item.name"
            @click="onSelectedCheckbox"
          ></v-checkbox>
          <v-icon v-if="item.isDir" color="yellow darken-1">
            {{ "mdi-folder" }}
          </v-icon>
          <v-img
            v-if="!item.isDir"
            :src="getImageUrl(item.Key)"
            class="white--text align-end"
          >
            <template v-slot:placeholder>
              <v-row class="ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import APP from "../appConfig";
import { getImgUrl } from "@/api/imgUtil";

export default {
  name: "FileManagerViewGallery",
  components: {},
  props: ["items", "init"],
  // computed: {
  //   selected: function () {
  //     return this.init;
  //   },
  // },
  watch: {
    init: function (newVal) {
      this.selected = newVal;
    },
  },
  data: () => ({ selected: [] }),
  async mounted() {
    console.log("FileManagerViewGallery: Mounted");
    console.log(this.items);
    console.log(this.init);
    this.selected = [...this.init];
    console.log(this.selected);
  },
  methods: {
    getImageUrl(key) {
      return getImgUrl(APP.S3.IMAGE_BUCKET, key, APP.IMG_SIZES["360"]);
    },
    onSelectedCheckbox() {
      console.log("FileManagerViewDetails onSelectedCheckbox");
      console.log(this.selected);
      this.$emit("onSelect", this.selected);
    },
  },
};
</script>

<style scoped>
.v-label {
  font-size: 4px;
}
</style>