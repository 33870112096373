// TODO: Hanlde period in folder name - breaking in GetMimeType (.club)

import File from "./File";
import APP from "../appConfig";

export default class Drive {
  constructor(drive, s3ListContents = null, cmsContents = null) {
    console.log("Drive constructor");
    this._drive = drive;
    this._bucket = this.getBucket(drive);
    this._prefix = this.getPrefix(drive);
    this._s3ListContents = s3ListContents;
    this._s3Idx =
      s3ListContents !== null
        ? this.createS3Idx(this._drive, this._s3ListContents)
        : {};
    this._tree = s3ListContents !== null ? this.createDirTree() : [];
    this._tree = cmsContents !== null ? this.createCMSTree() : [];
  }

  get drive() {
    return this._drive;
  }
  get bucket() {
    return this._bucket;
  }
  get prefix() {
    return this._prefix;
  }
  get s3ListContents() {
    return this._s3ListContents;
  }
  get tree() {
    return this._tree;
  }
  get s3Idx() {
    return this._s3Idx;
  }

  createS3Idx(drive, s3ListContents) {
    //console.log("Drive createS3Idx");
    let idx = {};
    for (let i = 0; i < s3ListContents.length; i++) {
      //console.log(s3ListContents[i]);
      let s3Obj = s3ListContents[i];
      idx[s3Obj.Key] = new File(drive, s3Obj);
    }
    //    console.log(idx);
    return idx;
  }

  // Private Functions
  getBucket(id) {
    return APP.FM.DRIVES[id].bucket;
  }
  getPrefix(id) {
    return APP.FM.DRIVES[id].prefix;
  }
  getFolders() {
    //console.log("Drive getFolders");
    let paths = this._s3ListContents.map((item) =>
      item.Key.substring(0, item.Key.lastIndexOf("/") + 1)
    );
    let uniquePaths = [...new Set(paths)];
    // remove blank path if exists
    const index = uniquePaths.indexOf("");
    if (index > -1) {
      uniquePaths.splice(index, 1);
    }

    // Add folders to Set for each of the paths
    const folderSet = new Set();
    for (const path of uniquePaths) {
      let folder = path;
      // Subtract last folder split as it will be ""
      let folderCnt = folder.split("/").length - 1;
      for (let i = 0; i < folderCnt; i++) {
        folderSet.add(folder);
        // Get next level up folder
        folder = folder.substring(0, folder.length - 1);
        folder = folder.substring(0, folder.lastIndexOf("/") + 1);
      }
    }

    // Create array of File Objects for each folder
    let foldersArray = [...folderSet];
    let folders = foldersArray.map(
      (folderKey) => new File(this._drive, { Key: folderKey })
    );
    //console.log({ folders });
    return folders;
  }

  createDirTree() {
    //console.log("Drive createDirTree");
    const folders = this.getFolders();

    const lookup = {};
    const rootFolder = new File(this._drive, { Key: "/" });
    lookup[rootFolder.key] = rootFolder;
    lookup[rootFolder.key].id = rootFolder.key;
    lookup[rootFolder.key].children = [];

    folders.forEach((item) => {
      lookup[item.key] = item;
      lookup[item.key].id = item.key;
      lookup[item.key].children = [];
    });

    const tree = [];
    folders.forEach((item) => {
      lookup[item.parent].children.push(item);
    });
    tree.push(rootFolder);
    //console.log({ tree });
    return tree;
  }
  createCMSTree() {
    //console.log("Drive createDirTree");
    const folders = this.getFolders();

    const lookup = {};
    const rootFolder = new File(this._bucket, null, { Key: "/" });
    lookup[rootFolder.key] = rootFolder;
    lookup[rootFolder.key].id = rootFolder.key;
    lookup[rootFolder.key].children = [];

    folders.forEach((item) => {
      lookup[item.key] = item;
      lookup[item.key].id = item.key;
      lookup[item.key].children = [];
    });

    const tree = [];
    folders.forEach((item) => {
      lookup[item.parent].children.push(item);
    });
    tree.push(rootFolder);
    //console.log({ tree });
    return tree;
  }
}
