<template>
  <v-app v-if="render">
    <v-navigation-drawer v-model="drawer" app clipped disable-resize-watcher>
      <NavDrawerMenu
        :currentUser="currentUser"
        :items="navDrawerMenuItems"
        @logout="logoutAction()"
      ></NavDrawerMenu>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-right
      color="primary"
      dark
      dense
      class="yellow--text"
    >
      <NavHeaderBar
        :currentUser="currentUser"
        @logout="logoutAction()"
        @publish="publishAction()"
      ></NavHeaderBar>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <v-main>
      <div class="magcontainer">
        <router-view @loginAction="loginAction" />
      </div>
    </v-main>
    <NavFooterBar :links="footerLinks"></NavFooterBar>
  </v-app>
</template>

<style scoped>
.magcontainer {
  padding: 0;
  height: 100vh;
}
.main {
}
</style>

<script>
import NavHeaderBar from "@/components/NavHeaderBar";
import NavDrawerMenu from "@/components/NavDrawerMenu";
import NavFooterBar from "@/components/NavFooterBar";

import { authLogout, authGetSession, authGetIdToken } from "@/api/ggiAuth";

export default {
  name: "App",
  components: {
    NavHeaderBar,
    NavDrawerMenu,
    NavFooterBar,
  },

  async mounted() {
    console.log("Mounting");
    this.currentUser = await authGetSession();
    console.log(`Mounted with User: ${this.currentUser}`);

    this.render = true;
  },
  methods: {
    async getIdTokenClick() {
      alert(await authGetIdToken());
    },
    async logoutAction() {
      console.log("logoutAction");
      authLogout();
      this.currentUser = await authGetSession();
      this.drawer = false;
      if (this.$route.path !== "/") this.$router.push("/");
    },
    async loginAction() {
      console.log("loginAction");
      this.currentUser = await authGetSession();
      this.drawer = false;
      if (this.$route.query.redirect != null) {
        this.$router.push(this.$route.query.redirect);
      } else if (this.$route.path !== "/") this.$router.push("/");
    },
  },
  data: () => ({
    render: false,
    drawer: false,
    currentUser: null,
    // publisher: null,
    navDrawerMenuItems: [
      { title: "About", icon: "mdi-information", route: "/about" },
      { title: "Contact", icon: "mdi-card-account-phone", route: "/contact" },
      {
        title: "File Manager",
        icon: "mdi-card-account-phone",
        route: { name: "FileManager" },
      },
      { title: "Home", icon: "mdi-home", route: "/" },
    ],
    footerLinks: [
      {
        title: "File Manager",
        icon: "mdi-card-account-phone",
        route: { name: "FileManager" },
      },
      {
        title: "CMS",
        icon: "mdi-card-account-phone",
        route: { name: "CMSManager", params: { action: "browse" } },
      },
      {
        title: "Edit",
        icon: "mdi-home",
        route: { name: "CMSManager", params: { action: "edit" } },
      },
      // {
      //   title: "Site Config",
      //   icon: "mdi-card-account-phone",
      //   route: { name: "ConfigManager" },
      // },
    ],
  }),
};
</script>