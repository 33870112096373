<template>
  <v-container fluid class="container">
    <v-row align="start" class="mx-auto">
      <v-col v-if="renderTree" cols="12" sm="5" md="4" lg="3">
        <FileManagerDirTree
          v-for="drive in drives"
          :key="drive.drive"
          :items="drive.tree"
          :initActive="activeItem"
          @onActive="onSelectDir"
        />
      </v-col>
      <v-col cols="12" sm="7" md="8" lg="9">
        <FileManagerViewGallery
          v-if="view === 'Gallery'"
          :items="items"
          :init="selected"
          @onSelect="onSelect"
        />
        <FileManagerViewDetails
          v-if="view === 'Details'"
          :items="items"
          :init="selected"
          @onSelectFile="onSelectFile"
          @cmsEditRoute="cmsEditRoute"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileManagerDirTree from "@/components/FileManagerDirTree";
import FileManagerViewGallery from "@/components/FileManagerViewGallery";
import FileManagerViewDetails from "@/components/FileManagerViewDetails";

export default {
  name: "FilBrowser",
  components: {
    FileManagerDirTree,
    FileManagerViewGallery,
    FileManagerViewDetails,
  },
  props: ["items", "drives", "activeItem"],
  data: () => ({
    selected: [],
    view: "Details",
    renderTree: false,
  }),
  mounted() {
    console.log("FileBrowser: Mounted");
    //console.log(this.items);
    //console.log(this.activeItem);
    //console.log(this.drives);
    //console.log(this.activeItem);
    this.renderTree = true;
  },
  methods: {
    onSelect(e) {
      console.log("FileBrowser: onSelect");
      //console.log({ e });
      this.selected = e;
    },
    onSelectFile(files) {
      console.log("FileBrowser: onSelectFile");
      //console.log({ files });
      this.$emit("onSelectFile", files);
    },
    onChangeView(e) {
      console.log("FileBrowser: onChangeView");
      //console.log({ e });
      this.view = e;
    },
    onSelectDir(folder) {
      console.log("FileBrowser: onSelectDir");
      //console.log({ folder });
      this.$emit("onSelectDir", folder);
    },
    cmsEditRoute(item) {
      console.log("FileBrowser: cmsEditRoute");
      //console.log(item);
      this.$emit("cmsEditRoute", item);
    },
  },
};
</script>

<style scoped>
.fixed-bar {
  position: -webkit-sticky;
  position: sticky;
  top: 3rem;
  z-index: 2;
  margin-bottom: 1rem;
}
.container {
  padding: 0;
}
</style>
