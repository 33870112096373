<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-card class="my-10 elevation-12" width="374">
        <v-card-title>Confirm</v-card-title>
        <v-card-text v-if="error != null">
          {{ error.message }}
        </v-card-text>

        <v-card-subtitle
          >Enter the verification code you should have recieved via
          email</v-card-subtitle
        >
        <v-card-text>
          <v-form>
            <v-text-field
              v-model="username"
              label="Email"
              prepend-icon="mdi-account"
              type="text"
            ></v-text-field>

            <v-text-field
              v-model="confirmationCode"
              label="Confirmation Code"
              prepend-icon="mdi-lock"
              type="text"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="cancelClick">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="confirmClick" color="primary">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { authConfirm } from "@/api/ggiAuth";

export default {
  name: "AuthConfirm",
  data: () => {
    return {
      username: "mgleason09@gmail.com",
      confirmationCode: null,
      error: null,
    };
  },
  methods: {
    async confirmClick() {
      console.log("confirmClick");
      try {
        let res = await authConfirm(this.username, this.confirmationCode);
        console.log({ res });
        if (res === "SUCCESS") alert(`Account Confirmed for ${this.username}`);
        // TODO: Go back to where we came from
        this.$router.replace("/");
      } catch (err) {
        this.error = err;
        console.log(`Confirm Error: `, err);
      }
    },
    async cancelClick() {
      console.log("cancelClick");
      this.error = null;
      // TODO: Go back to where we came from
      this.$router.replace("/");
    },
  },
};
</script>
