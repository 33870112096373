import CFG from "@/api/cms/CMS_Config";
import CMS_Item from "@/api/cms/CMS_Item";

export default class CMS_Item_Image extends CMS_Item {
  constructor(name, cmsItem = {}) {
    super(name, CFG.cms.contentTypes.image.type);
    this.subdomain = CFG.cms.contentTypes.image.subdomain;
    this.originalImageFolder = CFG.cms.contentTypes.image.originalImageFolder;
    this.bucket = CFG.cms.contentTypes.image.bucket;
    this.prefix = CFG.cms.contentTypes.image.prefix;
    this.contentKey = `${this.subdomain}/${CFG.cms.contentTypes.image.originalImageFolder}/${this.id}`;
    this.contentUrl = `${CFG.cms.contentTypes.image.imageUrl}/${CFG.cms.contentTypes.image.originalImageFolder}/${this.id}`;
    this.sizes = CFG.cms.contentTypes.image.sizes; // Used by lambda function image processor
    this.urls = null;
    this.imageSrc = cmsItem.imageSrc ? cmsItem.imageSrc : null;
    this.imageSrcset = cmsItem.imageSrcset ? cmsItem.imageSrcset : null;
  }
}
