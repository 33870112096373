<template>
  <v-card max-width="400" class="mx-auto">
    <v-container>
      <v-row dense>
        <v-col v-for="cmsItem in cmsItems" :key="cmsItem.id" cols="12">
          <CMSItemCard
            @cmsEditRoute="cmsEditRoute"
            :item="cmsItem"
          ></CMSItemCard>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import CMSItemCard from "@/components/CMSItemCard";

export default {
  name: "CMSItemCards",
  components: {
    CMSItemCard,
  },
  props: ["cmsItems"],
  data: () => ({}),
  mounted() {
    console.log("CMSItemCards: Mounted");
    console.log(this.cmsItems);
  },
  methods: {
    cmsEditRoute(item) {
      console.log("CMSItemCards: cmsEditRoute");
      console.log(item);
      this.$emit("cmsEditRoute", item);
    },
  },
};
</script>