<template>
  <v-container fluid>
    <v-row align="start" class="mx-auto">
      <v-col cols="12" sm="5" md="4" lg="3">
        <CMSBrowserTree
          :itemsTree="itemsTree"
          :activeItem="activeItem"
          @onActive="onActive"
        >
        </CMSBrowserTree>
      </v-col>
      <v-col cols="12" sm="7" md="8" lg="9">
        <CMSItemCards
          v-if="cmsItems.length"
          :cmsItems="cmsItems"
          @cmsEditRoute="cmsEditRoute"
          @onEdit="onEdit"
        ></CMSItemCards
      ></v-col>
    </v-row>
  </v-container>
</template>

<script>
import CMSBrowserTree from "@/components/CMSBrowserTree";
import CMSItemCards from "@/components/CMSItemCards";

export default {
  name: "CMSBrowser",
  components: {
    CMSBrowserTree,
    CMSItemCards,
  },
  props: ["itemsTree", "cmsItems", "activeItem"],
  data: () => ({}),
  mounted() {
    console.log("CMSBrowser: Mounted");
    console.log(this.cmsItems);
  },
  methods: {
    onActive(items) {
      console.log("CMSBrowser: onActive");
      console.log({ items });
      this.$emit("cmsBrowseRoute", items[0]);
    },
    cmsEditRoute(item) {
      console.log("CMSBrowser: cmsEditRoute");
      //console.log(item);
      this.$emit("cmsEditRoute", item);
    },
    onEdit(item) {
      console.log("CMSBrowser: onEdit");
      console.log(item);
      this.$emit("onEdit", item);
    },
  },
};
</script>

<style scoped>
</style>
